import { changeAppState } from "../../../../../../../../../../common/appState";
import { Quest } from "../types";

export const progressQuests = Array(10)
    .fill(undefined)
    .map<Quest>((_, i) => {
        const progress = 100 - i * 10;
        return {
            questKey: `progress_${progress}`,
            title: `Progress ${progress}%`,
            transitionTarget: () => {
                changeAppState("signInPanelState", {
                    type: "myPageTop",
                    initialView: "MyPageProgressPercentageCard",
                });
            },
            checkCleared: () =>
                Number(localStorage.getItem("user_totalProgress")) >= progress,
            reward: {
                type: "coin",
                value:
                    progress < 30
                        ? progress
                        : Number(
                              ((progress / 10) ** 6)
                                  .toString()
                                  .replace(/(?!^.)./g, "0")
                          ), // Progress 100パーセントでは報酬は1,000,000
            },
        };
    });
