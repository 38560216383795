import { sFetchJson } from "../common/util/sFetchJson";
import { StoryDesc } from "../types/stories";

const receiveStoriesType = "RECEIVE_STORIES";
const initialState = { allStories: [] };

export interface State {
    allStories: StoryDesc[];
}

export const actionCreators = {
    loadAllStories: () => async (dispatch: Function) => {
        try {
            const url = "api/Stories/GetAllStories";
            const allStories = await sFetchJson<StoryDesc[]>(url);

            dispatch({ type: receiveStoriesType, allStories });
        } catch (e) {
            // @ts-ignore
            window.location.reload(true);
        }
    },
};

export const reducer = (state: any, action: any) => {
    state = state || initialState;

    if (action.type === receiveStoriesType) {
        return {
            ...state,
            allStories: action.allStories,
        };
    }
    return state;
};
