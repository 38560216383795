import { makeStyles, Theme } from "@material-ui/core/styles";
import "bootstrap/dist/css/bootstrap.css";
import { useEffect, useRef, useState } from "react";
import Collapse from "reactstrap/lib/Collapse";
import Container from "reactstrap/lib/Container";
import Navbar from "reactstrap/lib/Navbar";
import NavbarBrand from "reactstrap/lib/NavbarBrand";
import NavbarToggler from "reactstrap/lib/NavbarToggler";
import NavLink from "reactstrap/lib/NavLink";
import { getAppState, useAppState } from "../../../../common/appState";
import { ARTICLES_URL } from "../../../../common/consts";
import { sleepAsync } from "../../../../common/functions";
import { useScreenSize } from "../../../../common/hooks/useScreenSize";
import { spaceBetween } from "../../../../common/util/Array/spaceBetween";
import { DarkLayer } from "../../../shared/DarkLayer";
import { A, Link } from "../../../shared/Link/LinkWithYouTube";
import { zIndex } from "../zIndex";
import { LoginIcon } from "./components/LoginIcon";
import { SocialIcon } from "./components/SocialIcon";
import "./style.css";

const links: { name: string; url: string }[] = [
    { name: "Hiragana / Katakana", url: "/hiragana-katakana" },
    { name: "Grammar", url: "/grammar" },
    { name: "Vocab", url: "/vocabulary-quiz" },
    { name: "Folktales", url: "/folktales" },
    { name: "Articles", url: ARTICLES_URL },
    { name: "Videos", url: `${ARTICLES_URL}/videos` },
    { name: "Games", url: "/ninja" },
];

function NavigationItems(props: { closeToggle: () => void }) {
    return (
        <ul
            className="navbar-nav flex-grow flexWrap"
            onClick={props.closeToggle}
        >
            {links.map(l => {
                if (l.url.startsWith("https://")) {
                    return (
                        <NavLink
                            key={l.name}
                            tag={A}
                            className="text-light dropdown nowrap"
                            href={l.url}
                        >
                            {l.name}
                        </NavLink>
                    );
                }
                return (
                    <NavLink
                        key={l.name}
                        tag={Link}
                        className="text-light dropdown nowrap"
                        to={l.url}
                    >
                        {l.name}
                    </NavLink>
                );
            })}
        </ul>
    );
}

export default function NavMenu() {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLElement>(null);
    const headerHeight = useHeaderHeight(ref.current);
    const hideAppBar = useHideAppBar();

    useEffect(() => {
        const onScroll = () => {
            setIsOpen(false);
        };
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    const c = useNavMenuStyles({ hideAppBar, isOpen, headerHeight });

    return (
        <>
            <header ref={ref} className={c.header}>
                <Navbar
                    variant="pills"
                    className={spaceBetween(
                        "navbar-inverse navbar-expand-md navbar-toggleable-md border-bottom box-shadow mb-3",
                        c.navBar
                    )}
                >
                    <Container>
                        <NavbarBrand
                            tag={Link}
                            to="/"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        >
                            <span
                                className={spaceBetween(
                                    "z-apps-title text-light",
                                    c.titleContainer
                                )}
                            >
                                Lingual Ninja
                            </span>
                        </NavbarBrand>
                        <Menus isOpen={isOpen} setIsOpen={setIsOpen} />
                    </Container>
                </Navbar>
            </header>
            <div className={c.spaceBelow} />
            <DarkLayer
                open={isOpen}
                onClick={() => {
                    setIsOpen(false);
                }}
                zIndex={zIndex.navMenu.smartphoneMenuOpenDarkLayer}
            />
        </>
    );
}
const topOverFlow = 50;
const useNavMenuStyles = makeStyles<
    Theme,
    { hideAppBar: boolean; isOpen: boolean; headerHeight: number }
>({
    titleContainer: {
        whiteSpace: "nowrap",
        fontWeight: "bold",
    },
    header: ({ hideAppBar, isOpen }) => ({
        top: hideAppBar ? -(100 + topOverFlow) : -topOverFlow,
        zIndex: zIndex.navMenu.header(isOpen),
    }),
    navBar: {
        backgroundColor: "#222222",
        marginTop: topOverFlow,
    },
    spaceBelow: ({ headerHeight }) => ({
        height: headerHeight,
        marginBottom: 14,
    }),
});

function Menus({
    isOpen,
    setIsOpen,
}: {
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
}) {
    const c = useMenusStyles();
    const { screenWidth } = useScreenSize();
    const isHamburger = screenWidth < 768;
    const iconMarginLeft = screenWidth / 30;

    return (
        <>
            <div className={c.iconsArea}>
                <NavbarToggler
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                    className="mr-2"
                />
                {isHamburger && (
                    <>
                        <SocialIcon
                            containerStyle={{
                                marginLeft: isOpen
                                    ? 0
                                    : `calc(${iconMarginLeft}px - 0.25rem)`, // Hamburger's right margin is 0.5rem,,
                            }}
                            isOpenHamburger={isOpen && isHamburger}
                            transitionClass={"t500ms"}
                        />
                        <LoginIcon
                            containerStyle={{
                                marginLeft: `calc(${iconMarginLeft}px + 0.25rem)`, // Hamburger's right margin is 0.5rem,
                            }}
                            isOpenHamburger={isOpen && isHamburger}
                            transitionClass={"t500ms"}
                        />
                    </>
                )}
            </div>
            <Collapse
                className="d-md-inline-flex flex-md-row-reverse"
                isOpen={isOpen}
                navbar
            >
                <NavigationItems
                    closeToggle={() => {
                        setIsOpen(false);
                    }}
                />
            </Collapse>
            {!isHamburger && (
                <>
                    <SocialIcon
                        containerStyle={{
                            marginLeft: iconMarginLeft,
                        }}
                        transitionClass={"t1s"}
                    />
                    <LoginIcon
                        containerStyle={{
                            marginLeft: Math.min(iconMarginLeft, 25),
                        }}
                        transitionClass={"t1s"}
                    />
                </>
            )}
        </>
    );
}
const useMenusStyles = makeStyles({
    iconsArea: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
});

function useHeaderHeight(headerElement: HTMLElement | null) {
    const [headerHeight, setHeaderHeight] = useAppState("headerHeight");
    const { screenWidth } = useScreenSize();
    useEffect(() => {
        if (headerElement) {
            setHeaderHeight(headerElement.clientHeight - (14 + topOverFlow));
        }
    }, [screenWidth, headerElement]);
    return headerHeight;
}

export function scrollToElement(
    element: HTMLElement | null,
    noSmooth?: boolean,
    headerShown?: boolean
) {
    if (headerShown) {
        const { headerHeight } = getAppState();
        const elementTop = element?.getBoundingClientRect()?.top || 0;

        window.scrollTo({
            top: window.scrollY + elementTop - headerHeight,
            behavior: noSmooth ? undefined : "smooth",
        });
        return;
    }

    hideHeaderTemporarily();
    element?.scrollIntoView(
        noSmooth
            ? true
            : {
                  behavior: "smooth",
              }
    );
}

export let hideHeaderTemporarily: () => void = () => {};
export let showHeader: () => void = () => {};

let previousScrollY = 0;
let isHidden = false;
let appBarTimer = 0;

function useHideAppBar() {
    const [hideAppBar, setHideAppBar] = useState(false);

    showHeader = () => {
        setHideAppBar(false);
        isHidden = false;
    };

    hideHeaderTemporarily = () => {
        setHideAppBar(true);
        isHidden = true;

        if (appBarTimer > 0) {
            clearTimeout(appBarTimer);
        }
        appBarTimer = window.setTimeout(showHeader, 5000);
    };

    useEffect(() => {
        const scrollHandler = async () => {
            await sleepAsync(10); // In case where starting point's scrollY is 0

            const isRapidScroll = window.scrollY > previousScrollY + 500;
            previousScrollY = window.scrollY;

            if (window.scrollY <= 0) {
                setHideAppBar(false);
                return;
            }

            if (isHidden) {
                return;
            }

            if (!isRapidScroll) {
                return;
            }

            hideHeaderTemporarily();
        };

        window.addEventListener("scroll", scrollHandler);
        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, []);

    return hideAppBar;
}
