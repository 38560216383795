import {
    changeAppState,
    getAppState,
} from "../../../../../../../../../../common/appState";
import { openOtherUserPanel } from "../../../../../../../../../shared/User/OtherUserPanel/functions";
import { Quest } from "../types";

export const settingQuests: Quest[] = [
    {
        questKey: "add-social-link",
        title: "Add social link",
        transitionTarget: () => {
            const { user } = getAppState();
            if (!user) {
                return;
            }
            changeAppState("signInPanelState", {
                type: "myPageTop",
                initialView: "MyPageBio",
                elementToEmphasize: "AddLinkButton",
            });
        },
        checkCleared: () => !!getAppState().user?.bioLinks?.length,
        reward: { type: "coin", value: 100 },
    },
    {
        questKey: "set-home-country",
        title: "Set your home country",
        transitionTarget: () => {
            const { user } = getAppState();
            if (!user) {
                return;
            }
            changeAppState("signInPanelState", {
                type: "myPageTop",
                initialView: "MyPageHomeCountry",
            });
        },
        checkCleared: () => !!getAppState().user?.countryCode,
        reward: { type: "coin", value: 100 },
    },
    {
        questKey: "set-avatar",
        title: "Set your avatar",
        transitionTarget: () => {
            const { user } = getAppState();
            if (!user) {
                return;
            }
            openOtherUserPanel(user.userId);
        },
        checkCleared: () => !!getAppState().user?.avatarPath,
        reward: { type: "coin", value: 100 },
    },
];
