import { makeStyles, Theme } from "@material-ui/core/styles";
import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";
import React from "react";

export function PointBox({
    language,
    children,
    style,
}: {
    language: string;
    children: React.ReactNode;
    style?: BaseCSSProperties;
}) {
    let content = "'NOTE'";
    let background = "#e3f5d8";
    let border = undefined;
    let titleBackgroundColor = "#22ac38";
    if (language) {
        const [title, color] = language.split("-");
        content = `'${title.split("_").join(" ")}'`;
        if (color) {
            background = "white";
            border = `solid 1px ${color}`;
            titleBackgroundColor = color;
        }
    }

    const c = useStyles({
        content,
        titleBackgroundColor,
        background,
        border,
        style,
    });

    return (
        <div>
            <div className={c.box}>{children}</div>
        </div>
    );
}
const useStyles = makeStyles<
    Theme,
    {
        content: string;
        titleBackgroundColor: string;
        background: string;
        border?: string;
        style?: BaseCSSProperties;
    }
>({
    box: ({ content, titleBackgroundColor, background, border, style }) => ({
        "&:before": {
            fontSize: 15,
            position: "absolute",
            top: -23,
            left: 0,
            height: 23,
            padding: "0 1em",
            content,
            color: "#fff",
            borderRadius: "10px 10px 0 0",
            background: titleBackgroundColor,
        },
        position: "relative",
        padding: "15px 20px 2px",
        color: "black",
        borderRadius: "2px 10px 10px 10px",
        background: background,
        margin: "45px 0 30px",
        display: "inline-block",
        border,
        ...style,
    }),
});
