import { changeAppState, getAppState } from "../../../../common/appState";

export function openOtherUserPanel(targetUserId: number) {
    const { otherUserPanelsState } = getAppState();
    const currentState = otherUserPanelsState.find(
        state => state.targetUserId === targetUserId
    );
    if (currentState) {
        changeAppState(
            "otherUserPanelsState",
            otherUserPanelsState.map(state =>
                state.targetUserId === targetUserId
                    ? { ...state, open: true, scrollToTop: true }
                    : state
            )
        );
        return;
    }
    changeAppState("otherUserPanelsState", [
        ...otherUserPanelsState,
        { targetUserId, open: true },
    ]);
}

