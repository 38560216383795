import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";

export function DarkLayer({
    zIndex,
    onClick,
    transitionMilliseconds = 500,
    open,
}: {
    zIndex: number;
    onClick?: () => void;
    transitionMilliseconds?: number;
    open: boolean;
}) {
    const [isShown, setShown] = useState(false);

    useEffect(() => {
        if (open) {
            setShown(open);
            return;
        }

        setTimeout(() => {
            setShown(false);
        }, transitionMilliseconds);
    }, [open, transitionMilliseconds]);

    if (!open && !isShown) {
        return null;
    }

    return (
        <LayerContent
            onClick={onClick}
            zIndex={zIndex}
            opacity={open && isShown ? 0.7 : 0}
            transitionDuration={`${transitionMilliseconds}ms`}
        />
    );
}

function LayerContent({
    zIndex,
    onClick,
    transitionDuration,
    opacity,
}: {
    zIndex: number;
    onClick?: () => void;
    transitionDuration: string;
    opacity: number;
}) {
    const c = useStyles({ zIndex, opacity, transitionDuration });
    return <div className={c.layer} onClick={onClick} />;
}
const useStyles = makeStyles<
    Theme,
    { zIndex: number; opacity: number; transitionDuration: string }
>({
    layer: ({ zIndex, opacity, transitionDuration }) => ({
        zIndex,
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "black",
        opacity,
        width: "100%",
        height: "100%",
        transitionDuration,
        transitionProperty: "opacity",
    }),
});
