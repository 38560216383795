import { changeAppState } from "../../../../../../../../../../../common/appState";
import { Item } from "../../Item";

export type InvitationCardKey = "invitation_card";

export const invitationBonusCoinPercentage = 20; // Corresponding value is in Z-Apps\Models\Item\Items\InvitationCardService.cs
export const invitationBonusXpPercentage = 10; // Corresponding value is in Z-Apps\Models\Item\Items\InvitationCardService.cs

export class InvitationCard extends Item {
    public key: InvitationCardKey = "invitation_card";
    public readonly name = "Invitation Card";
    public readonly price = 120;
    public readonly shouldUseOneByOne = true;
    UseButton = undefined;
    shouldWaitFetchResult = false;

    constructor() {
        super();
    }

    getExplanation = () =>
        "Inviting friends allows you to receive XP and coins automatically!";

    protected previousCheck = async () => {
        changeAppState("invitationCardPanelState", { open: true });
        return false; // No need to fetch at this timing because fetch will be done after they write a message
    };

    protected afterUsingItem = async () => {};
}
