import { getAppState } from "./appState";

export function pageViewGA(pathname: string) {
    if (gtag == null) {
        return;
    }

    const { user } = getAppState();
    const user_id = user?.userId;
    if (user_id === 1) {
        // Admin user
        return;
    }

    const user_name = user?.name;

    gtag("set", {
        page_path: pathname,
        page_title: pathname,
    });
    gtag("event", "page_view", {
        user_id,
        user_properties: {
            user_name,
            user_no: user_id,
        },
    });
}
