export function FormattedRemainingTime({
    remainingSeconds,
}: {
    remainingSeconds: number;
}) {
    const hour = Math.floor(remainingSeconds / 3600);
    const secExcludingHours = remainingSeconds - hour * 3600;
    const min = Math.floor(secExcludingHours / 60);
    const sec = secExcludingHours - min * 60;

    return (
        <>
            {hour > 0 && (
                <>
                    {hour}
                    <span className="small">h</span>{" "}
                </>
            )}
            {min > 0 && (
                <>
                    {min}
                    <span className="small">m</span>{" "}
                </>
            )}
            {sec}
            <span className="small">s</span>
        </>
    );
}
