import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import { changeAppState } from "../../../../common/appState";
import { appsPublicImg } from "../../../../common/consts";
import { useUser } from "../../../../common/hooks/useUser";
import { spaceBetween } from "../../../../common/util/Array/spaceBetween";

export function CoinCard() {
    const { user, isUserFetchDone } = useUser();
    const c = useCoinStyles();

    return (
        <Card
            className={spaceBetween(
                c.card,
                isUserFetchDone ? "opacity1" : "opacity0",
                "t1s"
            )}
            onClick={() => {
                changeAppState("vocabRoulettePanelState", { open: true });
            }}
        >
            <img
                src={`${appsPublicImg}system/coin.png`}
                className={c.coinImg}
            />
            <span className={c.coinNumber}>
                {(user?.coin || 0).toLocaleString()}
            </span>
            coins
        </Card>
    );
}
const useCoinStyles = makeStyles(theme => ({
    card: {
        position: "absolute",
        top: -35,
        height: 35,
        left: -5,
        display: "flex",
        alignItems: "center",
        paddingRight: 10,
        fontSize: "medium",
        cursor: "pointer",
    },
    coinImg: {
        width: 30,
        height: 30,
        marginRight: 8,
        marginLeft: 10,
        marginBottom: 1,
    },
    coinNumber: {
        fontSize: "large",
        color: theme.palette.primary.main,
        marginRight: 8,
        fontWeight: "bold",
    },
}));
