export const Z_APPS_SHORT_HOST = "lingual-ninja.com";
export const Z_APPS_SHORT_URL = `https://${Z_APPS_SHORT_HOST}`;
export const Z_APPS_HOST = `www.${Z_APPS_SHORT_HOST}`;
export const Z_APPS_TOP_URL = `https://${Z_APPS_HOST}`;

export const Z_APPS_ORIGIN_HOST = `origin.${Z_APPS_SHORT_HOST}`;
export const Z_APPS_COPY_HOST = `copy.${Z_APPS_SHORT_HOST}`;

export const BLOG_URL = `https://blog.${Z_APPS_SHORT_HOST}`;

export const ARTICLES_DOMAIN = `articles.${Z_APPS_SHORT_HOST}`;
export const ARTICLES_URL = `https://${ARTICLES_DOMAIN}/articles`;

/**
 * 料金がかさむため、CDN利用取りやめ
 */
// const CDN_URL = "https://ninja-cdn.azureedge.net";
// export const BLOB_URL = `${CDN_URL}/lingual-storage`;

export const BLOB_URL =
    "https://lingualninja.blob.core.windows.net/lingual-storage";

export const gameStorage = `${BLOB_URL}/game/`;

export const articlesStorage = `${BLOB_URL}/articles/`;

export const appsPublicImg = `${BLOB_URL}/appsPublic/img/`;

export const azureUrl = "japaneastzapps.azurewebsites.net";

export const youtubeChannelUrl = "https://www.youtube.com/c/LingualNinja";
export const youtubeSubscriptionUrl =
    "http://www.youtube.com/@LingualNinja?sub_confirmation=1";

export const adminUserId = 1;
