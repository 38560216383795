import { getAppState } from "../../../../../../../../../../common/appState";
import { getKanaPercentageFromStatus } from "../../../../../../../../zApps/parts/KanaQuiz/kanaProgress";
import { KanaType } from "../../../../../../../../zApps/parts/KanaQuiz/types";
import { Quest } from "../types";

export const kanaQuests: Quest[] = [
    {
        questKey: "katakana-100",
        title: "Katakana Quiz: 100%",
        transitionTarget: "/katakana-quiz",
        checkCleared: () => getKanaPercentage("Katakana") === 100,
        reward: { type: "coin", value: 10 },
    },
    {
        questKey: "katakana-50",
        title: "Katakana Quiz: 50%",
        transitionTarget: "/katakana-quiz",
        checkCleared: () => getKanaPercentage("Katakana") >= 50,
        reward: { type: "coin", value: 5 },
    },
    {
        questKey: "katakana-20",
        title: "Katakana Quiz: 20%",
        transitionTarget: "/katakana-quiz",
        checkCleared: () => getKanaPercentage("Katakana") >= 20,
        reward: { type: "coin", value: 2 },
    },
    {
        questKey: "hiragana-100",
        title: "Hiragana Quiz: 100%",
        transitionTarget: "/hiragana-quiz",
        checkCleared: () => getKanaPercentage("Hiragana") === 100,
        reward: { type: "coin", value: 10 },
    },
    {
        questKey: "hiragana-50",
        title: "Hiragana Quiz: 50%",
        transitionTarget: "/hiragana-quiz",
        checkCleared: () => getKanaPercentage("Hiragana") >= 50,
        reward: { type: "coin", value: 5 },
    },
    {
        questKey: "hiragana-20",
        title: "Hiragana Quiz: 20%",
        transitionTarget: "/hiragana-quiz",
        checkCleared: () => getKanaPercentage("Hiragana") >= 20,
        reward: { type: "coin", value: 2 },
    },
];

function getKanaPercentage(kanaType: KanaType) {
    const progress = getAppState().user?.progress;
    if (!progress) {
        return 0;
    }
    const status = JSON.parse(progress)[`KanaQuizStatus-${kanaType}`];
    if (!status) {
        return 0;
    }
    return getKanaPercentageFromStatus(kanaType, JSON.parse(status));
}
