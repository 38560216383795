export function getSerialNumbers(
    firstNumber: number,
    lastNumber: number
): number[] {
    if (lastNumber < firstNumber) {
        return getSerialNumbers(lastNumber, firstNumber).reverse();
    }
    return new Array(lastNumber - firstNumber + 1)
        .fill(undefined)
        .map((v, i) => i + firstNumber);
}
