import { theme } from "../../../../../../..";
import { changeAppState } from "../../../../../../../../../../common/appState";
import { User } from "../../../../../../../../../shared/User/types";
import { Item } from "../Item";

export type FriendLimitExpanderKey = "friend_limit_expander";

export class FriendLimitExpander extends Item {
    public readonly key = "friend_limit_expander";
    public readonly name = "Friend Limit Expander";
    public readonly price = 500;
    public readonly shouldUseOneByOne = false;
    UseButton = undefined;

    getExplanation = (user?: User) => {
        if (!user) {
            return "Expand your limitation! You can make 10 more friends!";
        }
        const limit = user.level + user.friendExpansion;
        return `Your number of friends is limited to ${limit}. Let's expand it to ${
            limit + 10
        }!`;
    };

    protected previousCheck = async () => {
        return true;
    };

    protected afterUsingItem = async (_userId: number, count: number) => {
        changeAppState("simpleDialogState", {
            open: true,
            title: "Limit Expanded!",
            titleStyle: { color: theme.palette.success.main },
            message: `You friend limit was successfully expanded! Now you can make ${
                10 * count
            } more friends!`,
            buttonLabel: "OK",
            onButtonClick: () => {
                changeAppState("simpleDialogState", {
                    open: false,
                });
            },
        });
    };
}
