import { RelatedUser } from "../../components/shared/User/types";
import { getAppState, useAppState } from "../appState";

export function useRelatedUsers() {
    const [relatedUsers] = useAppState("relatedUsers");
    return { relatedUsers };
}

export function getFriends(relatedUsers: RelatedUser[]): {
    friends: RelatedUser[];
} {
    return {
        friends: relatedUsers.filter(u => u.isFriend),
    };
}

export function getRequestedUsers(relatedUsers: RelatedUser[]): {
    requestedUsers: RelatedUser[];
} {
    const { user } = getAppState();
    if (!user || user.sul >= 2) {
        return {
            requestedUsers: [],
        };
    }
    return {
        requestedUsers: relatedUsers.filter(
            u => u.requestFromThem && !u.requestFromThem.approved
        ),
    };
}
