import { makeStyles, Theme } from "@material-ui/core/styles";
import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";
import Button from "reactstrap/lib/Button";
import { youtubeSubscriptionUrl } from "../../../common/consts";
import { sendClientOpeLog } from "../../../common/functions";
import { ATargetBlank } from "../Link/ATargetBlank";

export interface YouTubeVideoProps {
    videoId?: string;
    screenWidth: number;
    pageNameForLog: string;
    style?: BaseCSSProperties;
    buttonLabel?: string;
}

export function YouTubeVideo({
    videoId,
    screenWidth,
    pageNameForLog,
    style,
    buttonLabel,
}: YouTubeVideoProps) {
    const isWide = screenWidth > 600;
    const c = useStyles({ isWide, style });

    return (
        <div className={c.container}>
            <div className={c.innerContainer}>
                <div className={c.iframeContainer}>
                    {videoId ? (
                        <iframe
                            className={c.iframe}
                            src={"https://www.youtube.com/embed/" + videoId}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    ) : (
                        <div className={c.comingSoonOuter}>
                            <div className={c.comingSoon}>
                                Video lesson coming soon!
                            </div>
                        </div>
                    )}
                </div>
                <ATargetBlank
                    nofollow
                    href={youtubeSubscriptionUrl}
                    onClick={() => {
                        setTimeout(() => {
                            sendClientOpeLog(
                                "click YouTube channel",
                                `from ${pageNameForLog} video bottom`
                            );
                        }, 1000);
                    }}
                >
                    <Button className={c.subscribeButton}>
                        {buttonLabel ||
                            "Click to subscribe to this YouTube channel!"}
                    </Button>
                </ATargetBlank>
            </div>
        </div>
    );
}
const useStyles = makeStyles<
    Theme,
    { isWide: boolean; style?: BaseCSSProperties }
>(theme => ({
    container: ({ isWide, style }) => ({
        backgroundColor: isWide ? "rgb(231, 233, 231)" : undefined,
        padding: "5px 0",
        border: 0,
        ...style,
    }),
    innerContainer: {
        maxWidth: 600,
        marginLeft: "auto",
        marginRight: "auto",
    },
    iframeContainer: {
        position: "relative",
        width: "100%",
        paddingTop: "56.25%",
    },
    iframe: {
        position: "absolute",
        top: 0,
        right: 0,
        width: "100%",
        height: "100%",
        border: "none",
    },
    subscribeButton: {
        marginTop: 5,
        width: "100%",
        backgroundColor: "red",
        color: "white",
        transition: "all 500ms",
        "&:hover": {
            backgroundColor: "red",
            opacity: 0.8,
            transform: "scale(1.05, 1.05)",
        },
    },
    comingSoonOuter: {
        display: "flex",
        justifyContent: "center",
        position: "absolute",
        top: 0,
        right: 0,
        width: "100%",
        height: "100%",
        border: "none",
    },
    comingSoon: {
        width: "100%",
        maxWidth: 600,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.grey[800],
        backgroundColor: theme.palette.grey[100],
        fontSize: "xx-large",
        fontWeight: "bold",
        padding: 30,
        textAlign: "center",
        border: `solid 1px ${theme.palette.grey[300]}`,
        borderRadius: 5,
    },
}));
