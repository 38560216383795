import { useEffect } from "react";
import {
    changeAppState,
    getAppState,
    useAppState,
} from "../../../../../../../../../common/appState";
import { fetchPost } from "../../../../../../../../../common/util/fetch";
import { User } from "../../../../../../../../shared/User/types";
import { friendQuests } from "./friendQuests/friendQuests";
import { invitationQuests } from "./invitationQuests/invitationQuests";
import { settingQuests } from "./settingQuests/settingQuests";
import { getGrammarQuests } from "./studyQuests/grammarQuests";
import { kanaQuests } from "./studyQuests/kanaQuests";
import { progressQuests } from "./studyQuests/progressQuests";
import { vocabQuests } from "./studyQuests/vocabQuests";
import { ClearedQuest, Quest } from "./types";

// アプリケーションを最初に開いたときに、全てのgrammarQuestsを取得する。
// その後、ユーザーの操作によるイベントの中で、studyQuestsやquestThreadsが取得できるようになる。
// あるブラウザで始めてアクセスするときに、ログイン状態からいきなり始まる人はいないため、
// クエストの情報を取得したい際には、既にgrammarQuestsの中身は入っているはず……
// （ログインしないと、クエストは見られない。）
// すごくいまいちな設計だな…
export const questsManager = (() => {
    let grammarQuests: Quest[] = [];

    if (
        !("executingFrontEndTest" in window) ||
        window.executingFrontEndTest !== "jest"
    ) {
        // jestのテスト時は実行しない

        getGrammarQuests().then(g => {
            grammarQuests = g;
        });
    }

    return {
        getStudyQuests: (): Quest[] => [
            ...progressQuests,
            ...grammarQuests,
            ...vocabQuests,
            ...kanaQuests,
        ],
        getQuestThreads: (): Quest[][] => [
            grammarQuests,
            [...vocabQuests, ...kanaQuests],
            friendQuests,
            settingQuests,
            progressQuests,
            invitationQuests,
        ],
    };
})();

export async function setQuestState(claimedQuestKeys: string[]) {
    const { clearedQuests, ongoingQuests } = questsManager
        .getQuestThreads()
        .reduce<{
            clearedQuests: ClearedQuest[];
            ongoingQuests: Quest[];
        }>(
            (acc, quests) => {
                const { clearedQuests, ongoingQuests } =
                    getClearedAndOngoingQuests(quests, claimedQuestKeys);
                return {
                    clearedQuests: acc.clearedQuests.concat(clearedQuests),
                    ongoingQuests: acc.ongoingQuests.concat(ongoingQuests),
                };
            },
            {
                clearedQuests: [],
                ongoingQuests: [],
            }
        );

    changeAppState("questState", {
        clearedQuests,
        ongoingQuests,
        exclamationMark: clearedQuests.some(
            q => !claimedQuestKeys.includes(q.questKey)
        ),
    });
}

function getClearedAndOngoingQuests(
    quests: Quest[],
    claimedQuestKeys: string[]
) {
    return quests.reduce(
        (acc, val) => {
            const claimed = claimedQuestKeys.includes(val.questKey);
            if (claimed || val.checkCleared()) {
                return {
                    ...acc,
                    clearedQuests: [
                        ...acc.clearedQuests,
                        {
                            ...val,
                            claimed,
                        },
                    ],
                };
            }
            // Ongoing quest is only one for a thread
            return { ...acc, ongoingQuests: [val] };
        },
        { clearedQuests: [] as ClearedQuest[], ongoingQuests: [] as Quest[] }
    );
}

export async function fetchQuestState(myUserId: number) {
    const result = await fetchPost<number, string[]>(
        "api/Quest/GetAchievements",
        myUserId
    );
    if ("error" in result) {
        return;
    }
    setQuestState(result);
}

export function useClearedQuestCheck(user?: User) {
    const [relatedUsers] = useAppState("relatedUsers");

    useEffect(() => {
        if (!user) {
            return;
        }
        const {
            questState: { clearedQuests },
        } = getAppState();

        const previousClearedKeys = clearedQuests.map(q => q.questKey);
        const previousUnclearedQuests = questsManager
            .getQuestThreads()
            .flat()
            .filter(q => !previousClearedKeys.includes(q.questKey));

        if (!previousUnclearedQuests.some(q => q.checkCleared())) {
            // There's no newly cleared quest
            return;
        }
        fetchQuestState(user.userId);
    }, [relatedUsers, user]); // Add an item to the dependency array when the quest clear state might be changed by another state
}
