import { theme } from "../../../../../../..";
import {
    changeAppState,
    getAppState,
} from "../../../../../../../../../../common/appState";
import { getFriends } from "../../../../../../../../../../common/hooks/useRelatedUsers";
import { capitalizeInitialChar } from "../../../../../../../../../../common/util/String/capitalizeInitialChar";
import { getSeasonalAnimationKey } from "../../../../../../../../../shared/Animations/SeasonAnimation";
import { Item } from "../Item";

export type PresentBoxKey =
    | "small_present_box"
    | "present_box"
    | "big_present_box"
    | "present_box_tower"
    | "daily_present_box"
    | "weekly_present_box"
    | "xmas_present"
    | "handsel";

export class PresentBox extends Item {
    public readonly name: string;
    public readonly price: number | "notSold";
    public readonly presentCoins: number;
    public readonly shouldUseOneByOne = false;
    UseButton = undefined;
    shouldWaitFetchResult = false;

    constructor(public key: PresentBoxKey) {
        super();

        this.name = key
            .split("_")
            .map(k => capitalizeInitialChar(k))
            .join(" ");

        switch (key) {
            case "small_present_box": {
                this.price = 100;
                this.presentCoins = 30;
                break;
            }
            case "present_box": {
                this.price = 1000;
                this.presentCoins = 300;
                break;
            }
            case "big_present_box": {
                this.price = 10000;
                this.presentCoins = 3000;
                break;
            }
            case "present_box_tower": {
                this.price = 500000;
                this.presentCoins = 150000;
                break;
            }
            case "daily_present_box": {
                this.price = "notSold";
                this.presentCoins = 20;
                break;
            }
            case "weekly_present_box": {
                this.price = "notSold";
                this.presentCoins = 100;
                break;
            }
            case "xmas_present": {
                this.price =
                    getSeasonalAnimationKey(new Date()) === "xmas"
                        ? 1000
                        : "notSold";
                this.presentCoins = 1225;
                break;
            }
            case "handsel": {
                this.price =
                    getSeasonalAnimationKey(new Date()) === "kadomatsu"
                        ? 3000
                        : "notSold";
                this.presentCoins = 5000;
                break;
            }
            default: {
                const _exhaustiveCheck: never = key;
                this.price = "notSold";
                this.presentCoins = 0;
            }
        }
    }

    getExplanation = () => {
        if (this.key === "present_box_tower") {
            return `All of your friends will get ${this.presentCoins.toLocaleString()} coins!`;
        }
        return `All of your friends will get ${this.presentCoins.toLocaleString()} coins when you open this box!`;
    };

    protected previousCheck = async (count: number) => {
        if (getFriends(getAppState().relatedUsers).friends.length <= 0) {
            changeAppState("simpleDialogState", {
                open: true,
                title: "Sorry!",
                message: "You don't have friends to give this present to!",
                buttonLabel: "Find New Friends!",
                onButtonClick: () => {
                    changeAppState("simpleDialogState", {
                        open: false,
                    });
                    changeAppState("activeUsersPanelState", { open: true });
                },
            });
            return false;
        }

        // Show dialog without waiting for the actual result because it might take long.
        changeAppState("simpleDialogState", {
            open: true,
            title: "Success!",
            titleStyle: { color: theme.palette.success.main },
            message:
                count > 1
                    ? "Presents have been successfully sent to all of your friends!"
                    : "The present was successfully sent to all of your friends!",
            buttonLabel: "OK",
            onButtonClick: () => {
                changeAppState("simpleDialogState", {
                    open: false,
                });
            },
        });
        return true;
    };

    protected afterUsingItem = async () => {
        changeAppState("unreadMessages", [...getAppState().unreadMessages]); // To refresh MessagesCard
    };
}
