import { sleepAsync } from "../functions";

function getSFetchStorageKey(url: string) {
    return "sFetchJson_" + url;
}

export async function sFetchJson<T>(
    url: string,
    callbackUsingNewestData?: (fetchedData: T) => void
): Promise<T> {
    const storageKey = getSFetchStorageKey(url);
    const storageData = localStorage.getItem(storageKey);

    if (storageData) {
        sleepAsync(100).then(async () => {
            const data = await fetchAndStoreData<T>(url, storageKey);
            if (callbackUsingNewestData) {
                callbackUsingNewestData(data);
            }
        });
        return JSON.parse(storageData);
    }

    return fetchAndStoreData<T>(url, storageKey);
}

const fetchAndStoreData = async <T>(
    url: string,
    storageKey: string
): Promise<T> => {
    const result = await fetch(url);
    const data = await result.json();

    sleepAsync(1).then(() => {
        localStorage.setItem(storageKey, JSON.stringify(data));
    });

    return data;
};
