import { useEffect, useState } from "react";
import { AuthorPanelState } from "../components/shared/Author/types";
import { AddCoinsDialogState } from "../components/shared/Dialog/AddCoinsDialog/type";
import { FriendsLimitDialogState } from "../components/shared/Dialog/FriendsLimitDialog/type";
import { GuestMessageLimitDialogState } from "../components/shared/Dialog/GuestMessageLimitDialog/types";
import {
    GuestUserXpDialogState,
    RegisteredUserXpDialogState,
} from "../components/shared/Dialog/ResultXpDialog/types";
import { SimpleDialogState } from "../components/shared/Dialog/SimpleDialog/type";
import {
    Message,
    MessagePanelState,
} from "../components/shared/Message/MessagePanel/types";
import { ImageDetailPanelState } from "../components/shared/Panel/ImageDetail/types";
import { ShopPanelState } from "../components/shared/Panel/Shop/types";
import {
    SocialPanelState,
    UnreadComment,
} from "../components/shared/Panel/Social/Panel/types";
import { VocabRouletteState } from "../components/shared/Panel/VocabRoulette/types";
import { PaymentPanelState } from "../components/shared/Payment/PaymentForm/types";
import { ActiveUsersPanelState } from "../components/shared/User/ActiveUsers/types";
import { OtherUserPanelState } from "../components/shared/User/OtherUserPanel/types";
import { RelatedUser, User } from "../components/shared/User/types";
import { panelsToImport } from "../components/zApps/Layout";
import { PrivateLessonInfo } from "../components/zApps/Layout/Login/MyPage/MyPageTop/components/PrivateLesson/types";
import { InvitationBonus } from "../components/zApps/Layout/Login/MyPage/components/OpenableCards/BonusCard/types";
import { InvitationCardPanelState } from "../components/zApps/Layout/Login/MyPage/components/OpenableCards/ItemsCard/items/ItemClass/InvitationCard/types";
import { RegisteredEnvelopePanelState } from "../components/zApps/Layout/Login/MyPage/components/OpenableCards/ItemsCard/items/ItemClass/RegisteredEnvelope/types";
import { XpBoosterKey } from "../components/zApps/Layout/Login/MyPage/components/OpenableCards/ItemsCard/items/ItemClass/XpBooster/types";
import { PossessedItem } from "../components/zApps/Layout/Login/MyPage/components/OpenableCards/ItemsCard/items/types";
import {
    QuestState,
    initialQuestState,
} from "../components/zApps/Layout/Login/MyPage/components/OpenableCards/QuestsCard/quests/types";
import { SignInPanelState } from "../components/zApps/Layout/Login/types";
import { initialScreenSize } from "./hooks/useScreenSize";
import { sum } from "./util/Array/sum";

export type AppState = {
    headerHeight: number;
    isNoYouTubeAdMode: boolean;
    screenSize: { screenWidth: number; screenHeight: number };
    user?: User;
    isUserFetchDone: boolean;
    relatedUsers: RelatedUser[];
    items: PossessedItem[];
    isMainMounted: boolean;
    xpBeforeSignUp: number;
    panelAndDialogOpenedOrder: number[]; // Max length is 30. The last element is the id of the lastly opened panel.
    unreadMessages: Message[];
    questState: QuestState;
    isDailyBonusUnclaimed: boolean;
    xpBoostRemainingSeconds: number;
    lastUsedXpBooster?: XpBoosterKey | null;
    unreadComments: UnreadComment[];
    maintenanceRemainingMinutes: number;
    invitationBonusList: InvitationBonus[];
    privateLessonInfo: PrivateLessonInfo | null; // nullの場合はPrivateLesson受講可能生徒としてまだ登録されていない
    /**
     * RightPanel
     */
    signInPanelState: SignInPanelState;
    authorPanelState: AuthorPanelState;
    otherUserPanelsState: OtherUserPanelState[];
    messagePanelState: MessagePanelState;
    vocabRoulettePanelState: VocabRouletteState;
    shopPanelState: ShopPanelState;
    paymentPanelState: PaymentPanelState;
    activeUsersPanelState: ActiveUsersPanelState;
    socialPanelState: SocialPanelState;
    imageDetailPanelState: ImageDetailPanelState;
    invitationCardPanelState: InvitationCardPanelState;
    registeredEnvelopePanelState: RegisteredEnvelopePanelState;
    /**
     * Dialog
     */
    simpleDialogState: SimpleDialogState;
    guestUserXpDialogState: GuestUserXpDialogState;
    registeredUserXpDialogState: RegisteredUserXpDialogState;
    friendsLimitDialogState: FriendsLimitDialogState;
    guestMessageLimitDialogState: GuestMessageLimitDialogState;
    addCoinsDialogState: AddCoinsDialogState;
};

const appState = getInitialAppState();
function getInitialAppState(): AppState {
    return {
        headerHeight: 60,
        isNoYouTubeAdMode: false,
        screenSize: initialScreenSize,
        user: undefined,
        isUserFetchDone: false,
        relatedUsers: [],
        items: [],
        isMainMounted: true,
        xpBeforeSignUp: 0,
        panelAndDialogOpenedOrder: [],
        unreadMessages: [],
        questState: initialQuestState,
        isDailyBonusUnclaimed: false,
        xpBoostRemainingSeconds: 0,
        lastUsedXpBooster: undefined,
        unreadComments: [],
        maintenanceRemainingMinutes: 99,
        invitationBonusList: [],
        privateLessonInfo: null,
        /**
         * RightPanel
         *  ※ 追加時は、以下の「rightPanelStateKeys」にもキーを追加する
         */
        signInPanelState: { type: "close" },
        authorPanelState: { open: false },
        otherUserPanelsState: [],
        messagePanelState: "close",
        vocabRoulettePanelState: { open: false },
        shopPanelState: { open: false },
        paymentPanelState: "close",
        activeUsersPanelState: { open: false },
        socialPanelState: { open: false },
        imageDetailPanelState: { open: false },
        invitationCardPanelState: { open: false },
        registeredEnvelopePanelState: { open: false },
        /**
         * Dialog
         */
        simpleDialogState: { open: false },
        guestUserXpDialogState: "close",
        registeredUserXpDialogState: "close",
        friendsLimitDialogState: { type: "close" },
        guestMessageLimitDialogState: { type: "close" },
        addCoinsDialogState: { open: false },
    };
}

export function getAppState() {
    return { ...appState };
}

type ArrFnc = ((value: AppState[keyof AppState]) => void)[];

const setValues: {
    [key in keyof AppState]?: ArrFnc;
} = {};

export function changeAppState<T extends keyof AppState>(
    name: T,
    value: AppState[T]
) {
    appState[name] = value;
    setValues[name]?.forEach(f => f(value));
}

export function useAppState<T extends keyof AppState>(
    stateName: T
): [AppState[T], (value: AppState[T]) => void] {
    const [value, setValue] = useState<AppState[T]>(appState[stateName]);

    useEffect(() => {
        setValue(appState[stateName]);

        const arrFnc: ArrFnc = setValues[stateName] || [];
        setValues[stateName] = [...arrFnc, setValue] as ArrFnc;

        return () => {
            setValues[stateName] = setValues[stateName]?.filter(
                f => f !== setValue
            );
        };
    }, [stateName]);

    return [
        value,
        newValue => {
            changeAppState(stateName, newValue);
        },
    ];
}

export function initializeAppState<T extends (keyof AppState)[]>(...names: T) {
    const initialAppState = getInitialAppState();
    names.forEach(name => {
        changeAppState(name, initialAppState[name]);
    });
}

const rightPanelStateKeys = [
    "signInPanelState",
    "authorPanelState",
    "messagePanelState",
    "vocabRoulettePanelState",
    "shopPanelState",
    "paymentPanelState",
    "activeUsersPanelState",
    "socialPanelState",
    "imageDetailPanelState",
    "invitationCardPanelState",
] as const;
const multipleRightPanelStateKeys = ["otherUserPanelsState"] as const; // 別ターゲットに対して複数同時に開けるパネル（stateは配列）

export function getOpenedPanelCount() {
    // 現在openされているパネルの数を返す
    return sum(panelsToImport.map(r => Number(r.getOpenStatus(getAppState()))));
}

export function closeAllRightPanels() {
    initializeAppState(...rightPanelStateKeys);

    multipleRightPanelStateKeys.forEach(k => {
        changeAppState(
            k,
            appState[k].map(s => ({
                open: false,
                targetUserId: s.targetUserId,
            }))
        );
    });
}
