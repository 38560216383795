import {
    changeAppState,
    getAppState,
} from "../../../../../../../../../../common/appState";
import { getSerialNumbers } from "../../../../../../../../../../common/util/Array/getSerialNumbers";
import { Quest, Reward } from "../types";

export const friendQuests: Quest[] = [
    ...getSerialNumbers(1000, 2).map<Quest>(n => ({
        questKey: `${n}-friends`,
        title: `Add ${n} friends`,
        transitionTarget,
        checkCleared: () => checkFriendQuestCleared(n),
        reward: getReward(n),
    })),
    {
        questKey: "1-friend",
        title: "Add 1 friend",
        transitionTarget,
        checkCleared: () => checkFriendQuestCleared(1),
        reward: getReward(1),
    },
];

function checkFriendQuestCleared(num: number) {
    return (
        getAppState().relatedUsers.filter(
            u => u.requestFromMe && !u.requestFromMe.canceled
        ).length >= num
    );
}

function transitionTarget() {
    changeAppState("activeUsersPanelState", { open: true });
}

function getReward(num: number): Reward {
    return { type: "coin", value: num > 10 ? 5 : 100 };
}
