import { useEffect, useState } from "react";
import { Message } from "../../components/shared/Message/MessagePanel/types";
import { UnreadComment } from "../../components/shared/Panel/Social/Panel/types";
import { openOtherUserPanel } from "../../components/shared/User/OtherUserPanel/functions";
import { RelatedUser, User } from "../../components/shared/User/types";
import { PrivateLessonInfo } from "../../components/zApps/Layout/Login/MyPage/MyPageTop/components/PrivateLesson/types";
import { InvitationBonus } from "../../components/zApps/Layout/Login/MyPage/components/OpenableCards/BonusCard/types";
import { setItemsFromServerSide } from "../../components/zApps/Layout/Login/MyPage/components/OpenableCards/ItemsCard/items";
import { invitationHashKey } from "../../components/zApps/Layout/Login/MyPage/components/OpenableCards/ItemsCard/items/ItemClass/InvitationCard/functions";
import { useXpBoostTimer } from "../../components/zApps/Layout/Login/MyPage/components/OpenableCards/ItemsCard/items/ItemClass/XpBooster/XpBooster";
import { XpBoosterState_ServerSide } from "../../components/zApps/Layout/Login/MyPage/components/OpenableCards/ItemsCard/items/ItemClass/XpBooster/types";
import {
    PossessedItem,
    PossessedItem_ServerSide,
} from "../../components/zApps/Layout/Login/MyPage/components/OpenableCards/ItemsCard/items/types";
import {
    setQuestState,
    useClearedQuestCheck,
} from "../../components/zApps/Layout/Login/MyPage/components/OpenableCards/QuestsCard/quests/quests";
import {
    QuestState,
    initialQuestState,
} from "../../components/zApps/Layout/Login/MyPage/components/OpenableCards/QuestsCard/quests/types";
import { mergeLocalStorageAndSavedUserData } from "../../components/zApps/Layout/Login/MyPage/progressManager";
import { FetchResult } from "../../types/fetch";
import { changeAppState, getAppState, useAppState } from "../appState";
import { adminUserId, youtubeSubscriptionUrl } from "../consts";
import { sleepAsync } from "../functions";
import { fetchPost } from "../util/fetch";
import { getRequestedUsers } from "./useRelatedUsers";

/**
 * Procedure to add a state that needs to be fetched initially:
 *  - Add the state to AppState
 *  - Add data fetch to fetchInitialDataForUser (Called when a user visit with a valid cookie and when a user sign-in)
 *  - Clean it up in cleanUpForSignOut (Called when the user signs out)
 *  - If RightPanel needs to be opened initially, write it in openInitialPanelForUser or openInitialPanelIncludingGuestUser
 */

export function useStateLifecycle() {
    const [isInitialFetchFinished, setInitialFetchFinished] = useState(false);
    useEffect(() => {
        (async () => {
            const panelOpened = openInitialPanelIncludingGuestUser();

            await fetchUser();
            const { user } = getAppState();
            if (user) {
                await fetchDataForUser(user.userId, true);
                if (!panelOpened) {
                    await openInitialPanelForUser(user);
                }
                await openInitialDialogForUser(user.userId);
            }

            setInitialFetchFinished(true);
        })();
    }, []);

    const [user] = useAppState("user");
    const userId = user?.userId;
    useEffect(() => {
        if (isInitialFetchFinished) {
            // When sign in/out

            if (!userId) {
                // sign out
                cleanUpForSignOut();
                return;
            }

            // sign in
            (async () => {
                await fetchDataForUser(userId, true);
                await openInitialPanelForUser(user);
                await openInitialDialogForUser(userId);
            })();
        }
    }, [userId]);

    useEffect(() => {
        if (userId == null) {
            return;
        }

        const intervalId = setInterval(() => {
            fetchDataForUser(userId);
        }, 60 * 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [userId]);

    useClearedQuestCheck(user);
    useXpBoostTimer();
}

async function fetchDataForUser(userId: number, initial?: boolean) {
    const response = await fetch(
        `api/User/GetDataForUser?userId=${userId}&initial=${!!initial}`,
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
        }
    );
    const userDataResult: FetchResult<UserDataResult> = await response.json();

    if ("error" in userDataResult) {
        return;
    }

    const {
        initialDataForUser,
        dataForUser: {
            relatedUsers,
            unreadMessages,
            isDailyBonusUnclaimed,
            xpBoosterState,
            possessedItems,
            unreadComments,
            maintenanceRemainingMinutes,
            invitationBonusList,
            privateLessonInfo,
            sul,
        },
    } = userDataResult;
    const { items } = getAppState();

    // When initializing and once a minute
    // (Data that is necessary to be updated once a minute)
    changeAppState("relatedUsers", relatedUsers);
    changeAppState("unreadMessages", unreadMessages);
    changeAppState("isDailyBonusUnclaimed", isDailyBonusUnclaimed);
    changeAppState("xpBoostRemainingSeconds", xpBoosterState.remainingSec);
    changeAppState("lastUsedXpBooster", xpBoosterState.type);
    setItemsFromServerSide(
        possessedItems,
        items.map(item => item.item.key)
    );
    changeAppState("unreadComments", unreadComments);
    changeAppState("maintenanceRemainingMinutes", maintenanceRemainingMinutes);
    changeAppState("invitationBonusList", invitationBonusList);
    changeAppState("privateLessonInfo", privateLessonInfo);

    const currentUser = getAppState().user;
    if (currentUser && sul !== currentUser.sul) {
        changeAppState("user", { ...currentUser, sul });
    }
    checkExtremelyStrangeUser(sul);

    if (initialDataForUser) {
        // Only when initializing the user
        setQuestState(initialDataForUser.claimedQuestKeys);
    }
}
type InitialDataForUser = {
    claimedQuestKeys: string[];
};
type DataForUser = {
    relatedUsers: RelatedUser[];
    unreadMessages: Message[];
    isDailyBonusUnclaimed: boolean;
    xpBoosterState: XpBoosterState_ServerSide;
    possessedItems: PossessedItem_ServerSide[];
    unreadComments: UnreadComment[];
    maintenanceRemainingMinutes: number;
    invitationBonusList: InvitationBonus[];
    privateLessonInfo: PrivateLessonInfo | null; // null�̏ꍇ�APrivateLesson���p���[�U�[�Ƃ��Ă̓o�^�Ȃ�
    sul: number;
};
type UserDataResult = {
    initialDataForUser?: InitialDataForUser;
    dataForUser: DataForUser;
};

// Sign out
function cleanUpForSignOut() {
    changeAppState("relatedUsers", []);
    changeAppState("unreadMessages", []);
    changeAppState("questState", initialQuestState);
    changeAppState("xpBoostRemainingSeconds", 0);
}

/**
 * Open a panel for everyone even if they didn't login
 * @returns
 *  - true: A panel was opened
 *  - false: No panel was opened
 */
function openInitialPanelIncludingGuestUser(): boolean {
    const { hash } = window.location;
    /**
     * Developer panel
     */
    if (hash === "#KosukeZaizen") {
        changeAppState("authorPanelState", { open: true, title: "Developer" });
        return true;
    }
    /**
     * Roulette panel
     */
    if (hash === "#roulette") {
        changeAppState("vocabRoulettePanelState", { open: true });
        return true;
    }
    /**
     * Social panel
     */
    if (hash === "#social") {
        changeAppState("socialPanelState", { open: true });
        return true;
    }
    /**
     * OtherUser panel
     */
    if (/^#u\d+$/.test(hash)) {
        openOtherUserPanel(Number(hash.replace("#u", "")));
        return true;
    }
    /**
     * From invitation card
     */
    if (hash.startsWith(invitationHashKey)) {
        changeAppState("signInPanelState", {
            type: "signUp",
            encodedEmail: hash.replace(invitationHashKey, ""),
        });
    }

    return false;
}

/**
 * Open a panel for registered users
 */
export async function openInitialPanelForUser(
    user: User,
    absolutelyOpenMyPagePanel?: boolean
) {
    const {
        relatedUsers,
        unreadMessages,
        questState,
        items,
        invitationBonusList,
    } = getAppState();
    const result = checkStateToAlert(
        user,
        relatedUsers,
        unreadMessages,
        questState,
        items,
        invitationBonusList
    );
    switch (result) {
        case "Friend": {
            // Open the panel if there's a request or new friend
            changeAppState("signInPanelState", {
                type: "myPageTop",
                initialView: "MyPageFriendsCard",
            });
            return;
        }
        case "MessageFromMultipleUsers": {
            // If unread messages are from multiple users, open the message list
            changeAppState("signInPanelState", {
                type: "myPageTop",
                initialView: "MyPageMessagesCard",
            });
            return;
        }
        case "MessageFromSingleUser": {
            // If unread messages are from one user, open the conversation directly
            if (absolutelyOpenMyPagePanel) {
                changeAppState("signInPanelState", {
                    type: "myPageTop",
                });
                await sleepAsync(200);
            }
            changeAppState("messagePanelState", {
                targetUserId: unreadMessages[0].fromUserId,
            });
            return;
        }
        case "UnclaimedQuestReward": {
            changeAppState("signInPanelState", {
                type: "myPageTop",
                initialView: "MyPageQuestsCard",
            });
            return;
        }
        case "NewOrAddedItem": {
            if (absolutelyOpenMyPagePanel) {
                changeAppState("signInPanelState", {
                    type: "myPageTop",
                    initialView: "MyPageItemsCard",
                });
            }
            return;
        }
        case "InvitationBonus": {
            changeAppState("signInPanelState", {
                type: "myPageTop",
                initialView: "MyPageBonusCard",
            });
            return;
        }
        case "NoAlert": {
            return;
        }
        default: {
            const exhaustiveCheck: never = result;
        }
    }
}

/**
 * Open a dialog for registered users
 */
export async function openInitialDialogForUser(userId: number) {
    const { isDailyBonusUnclaimed } = getAppState();
    if (isDailyBonusUnclaimed) {
        await dailyBonusCoin(userId);
        return;
    }
}

async function dailyBonusCoin(userId: number) {
    const bonusCoinOptions = new Array(10)
        .fill(undefined)
        .map((v, i) => (i + 1) ** 2)
        .concat(new Array(30).fill(Math.ceil(Math.random() * 10)))
        .concat([500]);
    const bonusCoins =
        bonusCoinOptions[Math.floor(Math.random() * bonusCoinOptions.length)];
    changeAppState("addCoinsDialogState", {
        open: true,
        title: "Daily Login Bonus!",
        coins: bonusCoins,
    });
    await fetchDailyLoginBonusCoin(userId, bonusCoins);
}
async function fetchDailyLoginBonusCoin(userId: number, coins: number) {
    const result = await fetchPost<{ userId: number; coins: number }, User>(
        "api/User/ClaimDailyLoginBonusCoin",
        { userId, coins }
    );
    if ("error" in result) {
        return;
    }
    changeAppState("user", result);
}

type AlertState =
    | "NoAlert"
    | "Friend"
    | "MessageFromMultipleUsers"
    | "MessageFromSingleUser"
    | "UnclaimedQuestReward"
    | "NewOrAddedItem"
    | "InvitationBonus";
export function checkStateToAlert(
    user: User | undefined,
    relatedUsers: RelatedUser[],
    unreadMessages: Message[],
    questState: QuestState,
    items: PossessedItem[],
    invitationBonusList: InvitationBonus[]
): AlertState {
    if (!user) {
        return "NoAlert";
    }
    if (user.sul < 2) {
        // Only users whose SUL is less than 2 can communicate with other people
        const relationshipState = checkRelationshipState(
            relatedUsers,
            unreadMessages
        );
        if (relationshipState) {
            return relationshipState;
        }
    }
    const ownState = checkOwnState(questState, items, invitationBonusList);
    if (ownState) {
        return ownState;
    }
    return "NoAlert";
}
function checkRelationshipState(
    relatedUsers: RelatedUser[],
    unreadMessages: Message[]
) {
    /**
     * Friend requests of MyPage panel
     */
    if (
        getRequestedUsers(relatedUsers).requestedUsers.length > 0 ||
        relatedUsers.some(u => u.isNewFriend)
    ) {
        // Open the panel if there's a request or new friend
        return "Friend";
    }
    /**
     * Unread message
     */
    if (unreadMessages.length > 0) {
        if (
            Array.from(new Set(unreadMessages.map(m => m.fromUserId))).length >
            1
        ) {
            // If unread messages are from multiple users, open the message list
            return "MessageFromMultipleUsers";
        }
        // If unread messages are from one user, open the conversation directly
        return "MessageFromSingleUser";
    }
    return null;
}
function checkOwnState(
    questState: QuestState,
    items: PossessedItem[],
    invitationBonusList: InvitationBonus[]
) {
    /**
     * Unclaimed quest reward
     */
    if (questState.exclamationMark) {
        return "UnclaimedQuestReward";
    }
    /**
     * New items or added items
     */
    if (items.some(item => item.isNew || item.isAdded)) {
        return "NewOrAddedItem";
    }
    /**
     * Invitation bonus
     */
    if (invitationBonusList.filter(b => b.coins > 0 || b.xp > 0).length > 0) {
        return "InvitationBonus";
    }
    return null;
}

async function fetchUser() {
    const response = await fetch("api/Auth/GetUser", {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        credentials: "include",
    });
    const user: FetchResult<User> = await response.json();

    if ("error" in user) {
        changeAppState("user", undefined);
    } else {
        if (user.userId === adminUserId) {
            localStorage.setItem("isAdmin", "yes");
        }
        await mergeLocalStorageAndSavedUserData(user);
    }
    changeAppState("isUserFetchDone", true);
}

function checkExtremelyStrangeUser(sul: number): boolean {
    if (sul >= 10) {
        location.href = youtubeSubscriptionUrl;
        return true;
    }
    return false;
}
