import { getAppState } from "../../../../../../../../../../common/appState";
import { getSerialNumbers } from "../../../../../../../../../../common/util/Array/getSerialNumbers";
import { tryInvitationCard } from "../../../BonusCard/functions";
import { Quest } from "../types";

export const invitationQuests: Quest[] = [
    ...getSerialNumbers(200, 2).map<Quest>(n => ({
        questKey: `invite-${n}-friends`,
        title: `Invite ${n} friends`,
        transitionTarget: tryInvitationCard,
        checkCleared: () => checkFriendQuestCleared(n),
        reward: { type: "coin", value: 100000 },
    })),
    {
        questKey: "invite-1-friend",
        title: "Invite 1 friend",
        transitionTarget: tryInvitationCard,
        checkCleared: () => checkFriendQuestCleared(1),
        reward: { type: "coin", value: 100000 },
    },
];

function checkFriendQuestCleared(num: number) {
    return getAppState().invitationBonusList.length >= num;
}
