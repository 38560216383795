export const zIndex = {
    navMenu: {
        header: (isOpen: boolean) => (isOpen ? 1102 : 1100),
        smartphoneMenuOpenDarkLayer: 1101,
    },
    footer: 1100,
    folktaleFooterMenu: 1200,
    vocabListHeader: 1090,
    vocabListFooter: 1200,
    panelAndDialog: {
        darkLayer: (openedOrder: number) => 10060 - openedOrder * 2, // From 10000 to 10060
        panelOrDialog: (openedOrder: number) => 10061 - openedOrder * 2, // From 10001 to 10061
    },
    selectMenuItem: 10400,
    maintenanceAlert: 10500,
    maxValue: 2147483647,
} as const;
