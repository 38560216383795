export type TransitionFunc = () => void;

export type Quest = {
    questKey: string;
    title: string;
    transitionTarget: string | TransitionFunc;
    checkCleared: () => boolean;
    reward: Reward;
};
export interface ClearedQuest extends Quest {
    claimed: boolean;
}
export type Reward = { type: "xp" | "coin"; value: number };

export type QuestState = {
    clearedQuests: ClearedQuest[];
    ongoingQuests: Quest[];
    exclamationMark: boolean;
};
export const initialQuestState: QuestState = {
    clearedQuests: [],
    ongoingQuests: [],
    exclamationMark: false,
};
