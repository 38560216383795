import { Quest } from "../types";

export const vocabGenres = [
    { name: "wind", id: 82 },
    { name: "life_events", id: 80 },
    { name: "crimes", id: 79 },
    { name: "telephone", id: 78 },
    { name: "religions", id: 77 },
    { name: "animal_body_parts", id: 76 },
    { name: "water", id: 75 },
    { name: "rain", id: 73 },
    { name: "diplomacy", id: 72 },
    { name: "power_and_force", id: 71 },
    { name: "stone", id: 70 },
    { name: "music", id: 69 },
    { name: "power_plant", id: 67 },
    { name: "mathematics", id: 68 },
    { name: "science", id: 66 },
    { name: "fingers", id: 65 },
    { name: "monsters", id: 64 },
    { name: "hair", id: 63 },
    { name: "mountain", id: 62 },
    { name: "medicine", id: 61 },
    { name: "sleep", id: 59 },
    { name: "part_of_speech", id: 60 },
    { name: "shapes", id: 58 },
    { name: "finance", id: 57 },
    { name: "sickness", id: 51 },
    { name: "military_affairs", id: 49 },
    { name: "metals", id: 47 },
    { name: "chemical_substances", id: 46 },
    { name: "planet", id: 33 },
    { name: "business_industries", id: 45 },
    { name: "study", id: 52 },
    { name: "weapons", id: 40 },
    { name: "information_technology", id: 19 },
    { name: "hospital", id: 15 },
    { name: "politics", id: 50 },
    { name: "train_station", id: 56 },
    { name: "business", id: 11 },
    { name: "office", id: 30 },
    { name: "trees_and_flowers", id: 53 },
    { name: "books", id: 48 },
    { name: "time", id: 81 },
    { name: "large_numbers", id: 18 },
    { name: "small_numbers", id: 17 },
    { name: "vegetation", id: 20 },
    { name: "insects", id: 44 },
    { name: "marine_life", id: 38 },
    { name: "fish", id: 37 },
    { name: "reptiles", id: 43 },
    { name: "birds", id: 39 },
    { name: "animals", id: 1 },
    { name: "shopping", id: 29 },
    { name: "furniture", id: 9 },
    { name: "occupations", id: 3 },
    { name: "automobile", id: 31 },
    { name: "driving", id: 13 },
    { name: "home_electric_appliances", id: 36 },
    { name: "natural_disasters", id: 41 },
    { name: "ocean", id: 54 },
    { name: "places", id: 2 },
    { name: "japanese_national_holidays", id: 42 },
    { name: "school", id: 16 },
    { name: "internal_body_parts", id: 21 },
    { name: "body_parts", id: 7 },
    { name: "fruits", id: 74 },
    { name: "weather", id: 5 },
    { name: "JLPT_N3_Verbs_Part2", id: 121 },
    { name: "JLPT_N3_Verbs_Part1", id: 120 },
    { name: "JLPT_N3_Adjectives", id: 119 },
    { name: "JLPT_N3_Nouns_Part4", id: 118 },
    { name: "JLPT_N3_Nouns_Part3", id: 117 },
    { name: "JLPT_N3_Nouns_Part2", id: 116 },
    { name: "JLPT_N3_Nouns_Part1", id: 115 },
    { name: "kitchen_items", id: 14 },
    { name: "home", id: 12 },
    { name: "JLPT_N4_Verbs_Part7", id: 114 },
    { name: "JLPT_N4_Verbs_Part6", id: 113 },
    { name: "JLPT_N4_Verbs_Part5", id: 112 },
    { name: "JLPT_N4_Verbs_Part4", id: 111 },
    { name: "JLPT_N4_Verbs_Part3", id: 110 },
    { name: "JLPT_N4_Verbs_Part2", id: 109 },
    { name: "JLPT_N4_Verbs_Part1", id: 108 },
    { name: "JLPT_N4_Na-Adjectives", id: 107 },
    { name: "JLPT_N4_I-Adjectives", id: 106 },
    { name: "JLPT_N4_Adverbs", id: 105 },
    { name: "days_of_the_week", id: 8 },
    { name: "nature", id: 4 },
    { name: "JLPT_N4_Nouns_Part11", id: 104 },
    { name: "JLPT_N4_Nouns_Part10", id: 103 },
    { name: "JLPT_N4_Nouns_Part9", id: 102 },
    { name: "JLPT_N4_Nouns_Part8", id: 101 },
    { name: "JLPT_N4_Nouns_Part7", id: 100 },
    { name: "JLPT_N4_Nouns_Part6", id: 99 },
    { name: "JLPT_N4_Nouns_Part5", id: 98 },
    { name: "JLPT_N4_Nouns_Part4", id: 97 },
    { name: "JLPT_N4_Nouns_Part3", id: 96 },
    { name: "JLPT_N4_Nouns_Part2", id: 95 },
    { name: "JLPT_N4_Nouns_Part1", id: 94 },
    { name: "directions", id: 10 },
    { name: "travel", id: 35 },
    { name: "food", id: 34 },
    { name: "colors", id: 32 },
    { name: "JLPT_N5_Verbs_Part4", id: 27 },
    { name: "JLPT_N5_Verbs_Part3", id: 26 },
    { name: "JLPT_N5_Verbs_Part2", id: 25 },
    { name: "JLPT_N5_Verbs_Part1", id: 24 },
    { name: "JLPT_N5_Na-Adjectives", id: 23 },
    { name: "JLPT_N5_I-Adjectives", id: 22 },
    { name: "JLPT_N5_Adverbs", id: 28 },
    { name: "JLPT_N5_Nouns_Part11", id: 93 },
    { name: "JLPT_N5_Nouns_Part10", id: 92 },
    { name: "JLPT_N5_Nouns_Part9", id: 91 },
    { name: "JLPT_N5_Nouns_Part8", id: 90 },
    { name: "JLPT_N5_Nouns_Part7", id: 89 },
    { name: "JLPT_N5_Nouns_Part6", id: 88 },
    { name: "JLPT_N5_Nouns_Part5", id: 87 },
    { name: "JLPT_N5_Nouns_Part4", id: 86 },
    { name: "JLPT_N5_Nouns_Part3", id: 85 },
    { name: "seasons", id: 55 },
    { name: "JLPT_N5_Nouns_Part2", id: 84 },
    { name: "family", id: 6 },
    { name: "JLPT_N5_Nouns_Part1", id: 83 },
];

export const vocabQuests = vocabGenres.map<Quest>(g => ({
    questKey: g.name,
    title: g.name
        .split("_")
        .map(n => n[0].toUpperCase() + n.substring(1))
        .join(" "),
    transitionTarget: "/vocabulary-quiz/" + g.name,
    checkCleared: () => checkVocabCleared(g.id),
    reward: { type: "coin", value: 20 },
}));

function checkVocabCleared(vocabGenreId: number) {
    return (
        Number(
            localStorage.getItem(`vocab-quiz-percentage-${vocabGenreId}`)
        ) === 100
    );
}
