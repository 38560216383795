import { useEffect, useState } from "react";
import {
    AppState,
    changeAppState,
    getAppState,
    useAppState,
} from "../../appState";

export function usePanelAndDialogOpenOrder(
    globalPanelOrDialogState: AppState[keyof AppState],
    open: boolean,
    transitionMilliseconds: number
) {
    const [panelAndDialogOpenedOrder] = useAppState(
        "panelAndDialogOpenedOrder"
    );
    const [panelId, setPanelId] = useState(0);

    useEffect(() => {
        if (!open) {
            const timeoutId = setTimeout(() => {
                const currentPanelAndDialogOpenedOrder =
                    getAppState().panelAndDialogOpenedOrder; // Get realtime state not to see old values when the state of many panels changes at the same time
                if (currentPanelAndDialogOpenedOrder.includes(panelId)) {
                    // Remove closed panel's id
                    changeAppState(
                        "panelAndDialogOpenedOrder",
                        currentPanelAndDialogOpenedOrder.filter(
                            o => o !== panelId
                        )
                    );
                }
                // Clear panel id when it closes
                setPanelId(0);
            }, transitionMilliseconds + 100);
            return () => {
                clearTimeout(timeoutId);
            };
        }

        const currentPanelAndDialogOpenedOrder =
            getAppState().panelAndDialogOpenedOrder; // Get realtime state not to see old values when the state of many panels changes at the same time
        if (
            currentPanelAndDialogOpenedOrder[
                currentPanelAndDialogOpenedOrder.length - 1
            ] === panelId
        ) {
            // Already set as the lastly opened panel
            return;
        }
        const pId =
            currentPanelAndDialogOpenedOrder.length === 0
                ? 1
                : Math.max(...currentPanelAndDialogOpenedOrder) + 1;
        setPanelId(pId);
        changeAppState(
            "panelAndDialogOpenedOrder",
            [
                ...currentPanelAndDialogOpenedOrder.filter(o => o !== panelId), // Remove old
                pId, // Add new
            ].slice(-30) // Maximum length is 30
        );
    }, [open, globalPanelOrDialogState, panelId, transitionMilliseconds]);

    return {
        openedOrder: panelAndDialogOpenedOrder.includes(panelId)
            ? [...panelAndDialogOpenedOrder]
                  .reverse()
                  .findIndex(o => o === panelId)
            : 30,
        panelId,
    };
}
