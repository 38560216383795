import { capitalizeInitialChar } from "../../../../../../../../../../common/util/String/capitalizeInitialChar";
import {
    getGrammarSaveKey,
    sFetchAllGrammar,
} from "../../../../../progressManager/grammar";
import { Quest } from "../types";

export async function getGrammarQuests(): Promise<Quest[]> {
    const allGrammar = await sFetchAllGrammar();
    return [...allGrammar].reverse().map(g => {
        return {
            questKey: getGrammarQuestKey(g.path),
            title: convertPathToTitle(g.path),
            transitionTarget: `/grammar/${g.path}`,
            checkCleared: () =>
                localStorage.getItem(getGrammarSaveKey(g.path)) === "100",
            reward: { type: "coin", value: 500 },
        };
    });
}

export function convertPathToTitle(path: string) {
    return path
        .split("-")
        .map(p => capitalizeInitialChar(p))
        .join(" ");
}

export const grammarTestKeyInitial = "grammar-test-";
export function getGrammarQuestKey(path: string) {
    return `${grammarTestKeyInitial}${path}`;
}
