import { makeStyles, Theme } from "@material-ui/core/styles";
import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";
import { useEffect, useState } from "react";
import { AnimationEngine } from "../../../../common/animation";
import {
    changeAppState,
    getAppState,
    useAppState,
} from "../../../../common/appState";
import { appsPublicImg } from "../../../../common/consts";
import { sleepAsync } from "../../../../common/functions";
import { getRandomItem } from "../../../../common/util/Array/getRandomItem";
import { spaceBetween } from "../../../../common/util/Array/spaceBetween";
import {
    allItems,
    setItemsFromServerSide,
} from "../../../zApps/Layout/Login/MyPage/components/OpenableCards/ItemsCard/items";
import { Item } from "../../../zApps/Layout/Login/MyPage/components/OpenableCards/ItemsCard/items/Item";
import {
    ItemKey,
    PossessedItem_ServerSide,
} from "../../../zApps/Layout/Login/MyPage/components/OpenableCards/ItemsCard/items/types";
import { User } from "../../User/types";

const timeStep = 1000; //ms

const badNinja = appsPublicImg + "ninja_bad.png";
const rock = appsPublicImg + "rockRight.png";
const fire = appsPublicImg + "fireRight.png";
const flyingNinja = appsPublicImg + "flying-ninja.png";
const runningNinja = appsPublicImg + "ninja_hashiru.png";

interface StateToAnimate {
    shown: boolean;
    ninjaX: number;
    ninjaY: number;
    badNinjaX: number;
    turn: boolean;
    flyingNinjaPos: [number, number];
    flyingNinjaSpeed: [number, number];
    time: number;
    flyingNinjaDisplay: string;
}

const initialAnimationState: StateToAnimate = {
    shown: true,
    ninjaX: 3000,
    ninjaY: 0,
    badNinjaX: 3000,
    turn: false,
    flyingNinjaPos: [2500, 300],
    flyingNinjaSpeed: [0, 0],
    flyingNinjaDisplay: "block",
    time: 0,
};

const baseStyle: BaseCSSProperties = {
    position: "fixed",
    zIndex: 1000000000,
};

export let finishFooterAnimation: () => void;
export let restartFooterAnimation: () => void;

const smoothCSSProperty = {
    transitionDuration: `${timeStep / 1000}s`,
    transitionTimingFunction: "linear",
};

const smoothPosition = {
    transitionProperty: "bottom, left",
    ...smoothCSSProperty,
};

const hoverRotation = {
    transition: `bottom ${timeStep / 1000}s linear, left ${
        timeStep / 1000
    }s linear, transform 2s`,
};

export default function WelcomeAnimation() {
    const [flyingNinjaRotate, setFlyingNinjaRotate] = useState<
        Item | number | "noDrop" | undefined
    >(undefined);
    const [animationState, setAnimationState] = useState(initialAnimationState);
    const [user] = useAppState("user");

    useEffect(() => {
        finishFooterAnimation = () => {
            setAnimationState({ ...initialAnimationState, shown: false });
        };

        restartFooterAnimation = () => {
            setAnimationState(initialAnimationState);
        };

        const animation = new AnimationEngine<StateToAnimate>(
            initialAnimationState,
            ({
                ninjaX,
                ninjaY,
                badNinjaX,
                turn,
                flyingNinjaPos,
                flyingNinjaSpeed,
                flyingNinjaDisplay,
                time,
                ...rest
            }) => {
                if (time > 1000 / timeStep && time < 11200 / timeStep) {
                    ninjaX -= 0.5 * timeStep;
                    badNinjaX = ninjaX + 900;
                }

                if (time === Math.floor(11200 / timeStep)) {
                    turn = true;
                    ninjaY = 115;
                }

                if (time > 11200 / timeStep && time < 22000 / timeStep) {
                    ninjaX += 0.5 * timeStep;
                    badNinjaX = ninjaX + 600;
                }

                if (time > 22000 / timeStep && flyingNinjaPos[0] > -200) {
                    if (flyingNinjaPos[0] > 2000) {
                        flyingNinjaDisplay = "block";
                    }
                    flyingNinjaSpeed[1] +=
                        ((Math.random() - 0.499) * timeStep) / 30;

                    flyingNinjaPos[0] -= 0.3 * timeStep;
                    flyingNinjaPos[1] += flyingNinjaSpeed[1];
                }

                if (time % Math.floor(60000 / timeStep) === 0) {
                    flyingNinjaDisplay = "none";
                    flyingNinjaPos = [2500, 300];
                    flyingNinjaSpeed = [0, 0];
                    setFlyingNinjaRotate(undefined);
                }

                return {
                    ninjaX,
                    ninjaY,
                    badNinjaX,
                    turn,
                    flyingNinjaPos,
                    flyingNinjaSpeed,
                    flyingNinjaDisplay,
                    time: time + 1,
                    ...rest,
                };
            },
            setAnimationState
        );
        return animation.cleanUpAnimation;
    }, []);

    const innerWidth = window.innerWidth;
    const innerHeight = window.innerHeight;

    const squareLength = (innerWidth + innerHeight) / 2;

    const U = squareLength / 1000; // unit length

    const c = useStyles({
        animationState,
        U,
        flyingNinjaRotate: !!flyingNinjaRotate,
    });

    useEffect(() => {
        const user = getAppState().user;
        if (!flyingNinjaRotate || flyingNinjaRotate === "noDrop" || !user) {
            return;
        }
        if (typeof flyingNinjaRotate === "number") {
            // コイン
            sleepAsync(1000).then(async () => {
                const u = await fetchUpdateCoins(
                    user.userId,
                    flyingNinjaRotate
                );
                changeAppState("user", u);
            });
            return;
        }
        // アイテム
        fetchAddItem(user.userId, flyingNinjaRotate.key).then(items => {
            setItemsFromServerSide(items);
        });
    }, [flyingNinjaRotate]);

    if (!animationState.shown) {
        return null;
    }

    const rotateNinja = () => {
        if (flyingNinjaRotate) {
            return; // 既に設定済みであれば重複設定しない
        }
        const rand = Math.random();
        if (rand < 0.5) {
            setFlyingNinjaRotate(getRandomItem([1, 2, 3])); // コインドロップ
            return;
        }
        if (rand < 0.6) {
            const item = getRandomItem(
                allItems.filter(it => it.price !== "notSold")
            );
            setFlyingNinjaRotate(item); // アイテムドロップ
            return;
        }
        setFlyingNinjaRotate("noDrop"); // ドロップなし
    };

    return (
        <>
            <img
                src={runningNinja}
                alt="running ninja"
                className={c.runningNinja}
            />
            <img src={badNinja} alt="bad ninja" className={c.badNinja1} />
            <img src={badNinja} alt="bad ninja" className={c.badNinja2} />
            <img src={badNinja} alt="bad ninja" className={c.badNinja3} />
            <img src={badNinja} alt="bad ninja" className={c.badNinja4} />
            <img src={badNinja} alt="bad ninja" className={c.badNinja5} />
            <img src={badNinja} alt="bad ninja" className={c.badNinja6} />
            {animationState.time > 10000 / timeStep && (
                <>
                    <img src={rock} alt="rock" className={c.rock} />
                    <img src={fire} alt="fire" className={c.fire} />
                </>
            )}
            {animationState.time > 18000 / timeStep && (
                <img
                    src={flyingNinja}
                    alt="flying ninja"
                    className={c.flyingNinja}
                    onMouseOver={rotateNinja}
                    onClick={rotateNinja}
                    onTouchStart={rotateNinja}
                />
            )}
            {user &&
                animationState.flyingNinjaDisplay &&
                flyingNinjaRotate !== "noDrop" && (
                    <img
                        key="droppedItem"
                        alt="dropped item"
                        src={((): string => {
                            if (!flyingNinjaRotate) {
                                return `${appsPublicImg}item/small_present_box.png`;
                            }
                            if (typeof flyingNinjaRotate === "number") {
                                return `${appsPublicImg}system/coin.png`;
                            }
                            return `${appsPublicImg}item/${flyingNinjaRotate.key}.png`;
                        })()}
                        className={spaceBetween(
                            c.coinImg,
                            !!flyingNinjaRotate && c.coinImgAnime
                        )}
                    />
                )}
        </>
    );
}
const ninjaLength = 100;
const useStyles = makeStyles<
    Theme,
    { animationState: StateToAnimate; U: number; flyingNinjaRotate: boolean }
>({
    runningNinja: ({ animationState, U }) => ({
        willChange: "left",
        backfaceVisibility: "hidden",
        ...baseStyle,
        left: animationState.ninjaX * U,
        bottom: animationState.ninjaY * U,
        width: ninjaLength * U,
        transform: animationState.turn ? "scale(-1, 1)" : "",
        ...smoothPosition,
    }),
    badNinja1: ({ animationState, U }) => ({
        willChange: "left",
        backfaceVisibility: "hidden",
        ...baseStyle,
        left: animationState.badNinjaX * U,
        bottom: 0,
        width: ninjaLength * U,
        transform: animationState.turn ? "" : "scale(-1, 1)",
        ...smoothPosition,
    }),
    badNinja2: ({ animationState, U }) => ({
        willChange: "left",
        backfaceVisibility: "hidden",
        ...baseStyle,
        left: animationState.badNinjaX * U,
        bottom: 0,
        width: ninjaLength * U,
        transform: animationState.turn ? "" : "scale(-1, 1)",
        ...smoothPosition,
    }),
    badNinja3: ({ animationState, U }) => ({
        willChange: "left",
        backfaceVisibility: "hidden",
        ...baseStyle,
        left: (animationState.badNinjaX - 100) * U,
        bottom: 0,
        width: ninjaLength * U * 1.1,
        transform: animationState.turn ? "" : "scale(-1, 1)",
        ...smoothPosition,
    }),
    badNinja4: ({ animationState, U }) => ({
        willChange: "left",
        backfaceVisibility: "hidden",
        ...baseStyle,
        left: (animationState.badNinjaX - 200) * U,
        bottom: 0,
        width: ninjaLength * U,
        transform: animationState.turn ? "" : "scale(-1, 1)",
        ...smoothPosition,
    }),
    badNinja5: ({ animationState, U }) => ({
        willChange: "left",
        backfaceVisibility: "hidden",
        ...baseStyle,
        left: (animationState.badNinjaX - 300) * U,
        bottom: 0,
        width: ninjaLength * U * 1.1,
        transform: animationState.turn ? "" : "scale(-1, 1)",
        ...smoothPosition,
    }),
    badNinja6: ({ animationState, U }) => ({
        willChange: "left",
        backfaceVisibility: "hidden",
        ...baseStyle,
        left: (animationState.badNinjaX - 400) * U,
        bottom: 0,
        width: ninjaLength * U * 1.1,
        transform: animationState.turn ? "" : "scale(-1, 1)",
        ...smoothPosition,
    }),
    rock: ({ animationState, U }) => ({
        willChange: "lef",
        backfaceVisibility: "hidden",
        ...baseStyle,
        left: (animationState.ninjaX - 5) * U,
        bottom: 0,
        width: ninjaLength * U * 1.3,
        zIndex: 1000000001,
        ...smoothPosition,
    }),
    fire: ({ animationState, U }) => ({
        willChange: "left",
        backfaceVisibility: "hidden",
        ...baseStyle,
        left: (animationState.ninjaX - ninjaLength) * U,
        bottom: 0,
        width: ninjaLength * U * 1.3,
        ...smoothPosition,
    }),
    flyingNinja: ({ animationState, U, flyingNinjaRotate }) => ({
        willChange: "left, bottom",
        backfaceVisibility: "hidden",
        ...baseStyle,
        left: animationState.flyingNinjaPos[0] * U,
        bottom: animationState.flyingNinjaPos[1] * U,
        width: ninjaLength * U * 1.5,
        display: animationState.flyingNinjaDisplay,
        ...hoverRotation,
        transform: flyingNinjaRotate ? "rotate(1440deg)" : undefined,
    }),
    coinImg: ({ animationState, U, flyingNinjaRotate }) => {
        const size = ninjaLength * U * 0.4;
        return {
            width: size,
            height: size,
            willChange: "left, bottom",
            backfaceVisibility: "hidden",
            ...baseStyle,
            left: (animationState.flyingNinjaPos[0] + 75) * U,
            bottom: (animationState.flyingNinjaPos[1] + 75) * U,
            ...smoothPosition,
            opacity: flyingNinjaRotate ? 1 : 0,
        };
    },
    coinImgAnime: {
        animation: "$fallingAnime 2s ease-in",
        animationIterationCount: 1,
        animationFillMode: "forwards",
    },
    "@keyframes fallingAnime": {
        "0%": {
            transform: "translate(0px, 0px)",
            animationTimingFunction: "ease-out",
        },
        "20%": {
            transform: "translate(0px, -100px)",
            animationTimingFunction: "ease-in",
        },
        "100%": {
            transform: "translate(0px, 2000px)",
            animationTimingFunction: "ease-in",
        },
    },
});

async function fetchUpdateCoins(
    userId: number,
    coinsToAdd: number
): Promise<User> {
    const formData = new FormData();
    formData.append("userId", userId.toString());
    formData.append("coinsToAdd", coinsToAdd.toString());

    const res = await fetch("api/User/GetDroppedCoins", {
        method: "POST",
        body: formData,
    });
    return res.json();
}

async function fetchAddItem(
    userId: number,
    itemKey: ItemKey
): Promise<PossessedItem_ServerSide[]> {
    const formData = new FormData();
    formData.append("userId", userId.toString());
    formData.append("itemKey", itemKey);

    const res = await fetch("api/Item/GetDroppedItem", {
        method: "POST",
        body: formData,
    });
    return res.json();
}
