import {
    changeAppState,
    getAppState,
} from "../../../../../../../../../../../common/appState";
import { getFriends } from "../../../../../../../../../../../common/hooks/useRelatedUsers";
import { Item } from "../../Item";

export type RegisteredEnvelopeKey = "registered_envelope";

export class RegisteredEnvelope extends Item {
    public key: RegisteredEnvelopeKey = "registered_envelope";
    public readonly name = "Registered Envelope";
    public readonly price = 200;
    public readonly shouldUseOneByOne = true;
    UseButton = undefined;
    shouldWaitFetchResult = false;

    constructor() {
        super();
    }

    getExplanation = () =>
        "You can send up to 500,000 coins directly to one specific friend!";

    protected previousCheck = async () => {
        if (getFriends(getAppState().relatedUsers).friends.length <= 0) {
            changeAppState("simpleDialogState", {
                open: true,
                title: "Sorry!",
                message: "You don't have friends to send coins to!",
                buttonLabel: "Find New Friends!",
                onButtonClick: () => {
                    changeAppState("simpleDialogState", {
                        open: false,
                    });
                    changeAppState("activeUsersPanelState", { open: true });
                },
            });
            return false;
        }

        changeAppState("registeredEnvelopePanelState", { open: true });
        return false; // No need to fetch at this timing because fetch will be done after they write a message
    };

    protected afterUsingItem = async () => {};
}
