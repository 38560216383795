import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";
import CloseIcon from "@material-ui/icons/Close";
import { ReactNode, RefObject, useEffect, useState } from "react";
import {
    AppState,
    getAppState,
    getOpenedPanelCount,
} from "../../../common/appState";
import { pageViewGA } from "../../../common/googleAnalytics";
import { useOpenAnimationFinished } from "../../../common/hooks/panelAndDialog/useOpenAnimationFinished";
import { usePanelAndDialogOpenOrder } from "../../../common/hooks/panelAndDialog/usePanelAndDialogOpenOrder";
import { useScreenSize } from "../../../common/hooks/useScreenSize";
import { panelsToImport } from "../../zApps/Layout";
import { zIndex } from "../../zApps/Layout/zIndex";
import ShurikenProgress from "../Animations/ShurikenProgress";

export function RightPanel({
    open,
    onClose,
    children,
    style,
    childrenContainerStyle,
    panelWidth,
    transitionMilliseconds = 1000,
    globalPanelState,
    isChildrenUnmounted,
    scrollableContainerRef,
    panelName,
    topItem,
}: {
    open: boolean;
    onClose: () => void;
    children: ReactNode;
    style?: BaseCSSProperties;
    childrenContainerStyle?: BaseCSSProperties;
    panelWidth: number;
    transitionMilliseconds?: number;
    globalPanelState?: AppState[keyof AppState]; // When the panel's global state changed, zIndex increases.
    isChildrenUnmounted?: boolean;
    scrollableContainerRef?: RefObject<HTMLDivElement>;
    panelName?: string;
    topItem?: ReactNode;
}) {
    const { screenWidth } = useScreenSize();
    const { openedOrder } = usePanelAndDialogOpenOrder(
        globalPanelState,
        open,
        transitionMilliseconds
    );
    useEffect(() => {
        if (openedOrder > 0 || !panelName) {
            return;
        }
        pageViewGA(panelName);
    }, [openedOrder, panelName]);

    const { isCompletelyClosed, isCompletelyOpened } = useOpenState(
        open,
        transitionMilliseconds
    );

    const c = useStyles({
        isCompletelyOpened,
        transitionDuration: `${transitionMilliseconds}ms`,
        style,
        childrenContainerStyle,
        screenWidth,
        panelWidth,
        openedOrder,
    });

    const { isOpenAnimationFinished } = useOpenAnimationFinished(
        isCompletelyOpened,
        transitionMilliseconds
    );

    if (isCompletelyClosed) {
        return null;
    }

    return (
        <>
            <div
                className={c.darkLayer}
                onClick={isOpenAnimationFinished ? onClose : undefined}
            />
            <Card className={c.panel}>
                <Button
                    variant="contained"
                    className={c.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon className={c.closeIcon} />
                </Button>

                {topItem}

                <div
                    className={c.childrenContainer}
                    ref={scrollableContainerRef}
                >
                    {isChildrenUnmounted && open ? (
                        <ShurikenProgress
                            size="20%"
                            style={{ marginTop: 100 }}
                        />
                    ) : (
                        <>{children}</>
                    )}
                </div>
            </Card>
        </>
    );
}
export const rightPanelTopGap = 30;
export const rightPanelTopBarHeight = 38;
const useStyles = makeStyles<
    Theme,
    {
        isCompletelyOpened: boolean;
        transitionDuration: string;
        style?: BaseCSSProperties;
        childrenContainerStyle?: BaseCSSProperties;
        screenWidth: number;
        panelWidth: number;
        openedOrder: number; // 0 to 30, The last opened panel or dialog is 0.
    }
>(({ palette }) => ({
    darkLayer: ({ isCompletelyOpened, transitionDuration, openedOrder }) => ({
        zIndex: zIndex.panelAndDialog.darkLayer(openedOrder),
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "black",
        opacity: isCompletelyOpened ? 0.7 : 0,
        width: "100%",
        height: "100%",
        transitionDuration,
        transitionProperty: "opacity",
    }),
    panel: ({
        isCompletelyOpened,
        transitionDuration,
        style,
        screenWidth,
        panelWidth,
        openedOrder,
    }) => ({
        height: `calc(100% - ${rightPanelTopGap}px)`,
        position: "fixed",
        bottom: 0,
        right: isCompletelyOpened ? 0 : -(screenWidth + 20),
        borderRadius: "20px 0 0 0",
        padding: `${rightPanelTopBarHeight}px 0 0 0`,
        transitionDuration,
        transitionProperty: "right",
        zIndex: zIndex.panelAndDialog.panelOrDialog(openedOrder),
        overflow: "hidden",
        ...style,
        width: Math.min(screenWidth, panelWidth),
    }),
    closeButton: {
        borderRadius: "50%",
        maxWidth: 30,
        maxHeight: 30,
        minWidth: 30,
        minHeight: 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: 5,
        left: 5,
        backgroundColor: palette.success.main,
        "&:hover": {
            backgroundColor: palette.success.light,
        },
        color: "white",
        lineHeight: 1,
        padding: 0,
    },
    closeIcon: { width: 20, height: 20 },
    childrenContainer: ({ childrenContainerStyle }) => ({
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        overscrollBehaviorY: "contain",
        ...childrenContainerStyle,
    }),
}));

function useOpenState(open: boolean, transitionMilliseconds: number) {
    const [isContentShown, setIsContentShown] = useState(false);

    useEffect(() => {
        const { style } = window.document.body;

        if (open && !isContentShown) {
            // when it opens

            if (getOpenedPanelCount() < 2) {
                // 1つめのパネルを開く際のみwindowのスクロールバーを消す（２回目はちゃんと window.scrollY が取れない）

                /**
                 * Prevent body from scrolling in old iPhone：
                 *   - https://coliss.com/articles/build-websites/operation/javascript/prevent-page-scrolling-when-a-modal-is-open.html
                 *   - https://zenn.dev/toono_f/articles/f5b20817a15b9a#%E3%82%B9%E3%82%AF%E3%83%AD%E3%83%BC%E3%83%AB%E4%BD%8D%E7%BD%AE%E3%82%92%E4%BF%9D%E6%8C%81%E3%81%97%E3%81%9F%E4%B8%8A%E3%81%A7body%E3%81%ABposition%3A-fixed%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B
                 */
                style.overflowY = "hidden";
                style.height = "100vh";
                style.width = "100vw";
                style.top = `-${window.scrollY}px`;
                style.left = `-${window.scrollX}px`;
                style.position = "fixed";
            }

            const id = setTimeout(() => {
                setIsContentShown(true);
            }, 50);
            return () => {
                clearTimeout(id);
            };
        }

        if (!open && isContentShown) {
            // when it closes

            const appState = getAppState();
            if (!panelsToImport.some(r => r.getOpenStatus(appState))) {
                const top = style.top;
                const left = style.left;

                // All other panels are also closed
                style.overflowY = "auto";
                style.height = "";
                style.width = "";
                style.top = "";
                style.left = "";
                style.position = "";

                window.scrollTo(
                    parseInt(left || "0") * -1,
                    parseInt(top || "0") * -1
                );
            }
            const id = setTimeout(() => {
                setIsContentShown(false);
            }, transitionMilliseconds);
            return () => {
                clearTimeout(id);
            };
        }
    }, [open, transitionMilliseconds]);

    return {
        isCompletelyOpened: open && isContentShown,
        isCompletelyClosed: !open && !isContentShown,
    };
}
