import {
    changeAppState,
    getAppState,
} from "../../../../../../../../common/appState";

export function tryInvitationCard() {
    if (getAppState().items.some(item => item.item.key === "invitation_card")) {
        changeAppState("signInPanelState", {
            type: "myPageTop",
            initialView: "MyPageItemsCard",
            itemKeyToFocus: "invitation_card",
        });
        return;
    }
    changeAppState("shopPanelState", {
        open: true,
        focusedItemKey: "invitation_card",
    });
}

