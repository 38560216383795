import { useAppState } from "../appState";

export function useScreenSize() {
    const [screenSize] = useAppState("screenSize");
    return screenSize;
}

export const initialScreenSize = {
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
};
