import { makeStyles, Theme } from "@material-ui/core/styles";
import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";
import { useEffect, useRef, useState } from "react";
import { appsPublicImg } from "../../../../common/consts";
import { spaceBetween } from "../../../../common/util/Array/spaceBetween";
import { sFetchJson } from "../../../../common/util/sFetchJson";
import "./animation.css";
import { FallingImage } from "./type";

interface Leaf {
    id: number;
    ageCount: number;
    initialX: number;
}

interface Props {
    frequencySec: number;
    screenWidth: number;
    screenHeight?: number;
    season?: string;
    isFestivalHidden?: boolean;
    fallingImgStyle?: BaseCSSProperties;
}
export const SeasonAnimation = ({
    frequencySec,
    screenWidth,
    season: pSeason,
    isFestivalHidden,
    fallingImgStyle,
    screenHeight = window.innerHeight,
}: Props) => {
    const [scale, setScale] = useState((screenWidth + screenHeight) / 1000);
    const [leaves, setLeaves] = useState<Leaf[]>([]);
    const [season, setSeason] = useState<string>("none");
    const [seasonItems, setSeasonItems] = useState<FallingImage[]>([]);

    const countRef = useRef(0);
    const lsRef = useRef<Leaf[]>([]);

    useEffect(() => {
        const load = async () => {
            if (pSeason === "none") {
                return;
            }
            const fallingImages = await getFallingImages();
            setSeasonItems(fallingImages);

            if (pSeason) {
                if (fallingImages.some(im => im.name === pSeason)) {
                    setSeason(pSeason);
                } else {
                    setSeason("none");
                }
            } else {
                setSeason(getSeasonalAnimationKey(new Date()));
            }
        };
        load();
    }, [pSeason]);

    useEffect(() => {
        setScale((screenWidth + screenHeight) / 1000);

        const add = () => {
            //各葉っぱは20秒で消える
            const newLeaves = lsRef.current
                .map(l => ({ ...l, ageCount: l.ageCount + 1 }))
                .filter(l => l.ageCount <= 20);

            countRef.current++;
            if (countRef.current % frequencySec === 0) {
                newLeaves.push({
                    id: countRef.current,
                    ageCount: 0,
                    initialX: (screenWidth / 6) * (Math.random() * 11),
                });
            }

            setLeaves(newLeaves);
            lsRef.current = newLeaves;
        };

        add();
        const intervalId = window.setInterval(add, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [screenWidth, screenHeight, frequencySec]);

    const seasonItem = seasonItems?.find(item => item.name === season);
    const c = useStyles({ screenWidth });

    return (
        <>
            {!isFestivalHidden && (
                <img
                    alt="japanese festival"
                    title="japanese festival"
                    src={appsPublicImg + "japanese-festival.png"}
                    className={c.festivalImg}
                />
            )}
            {season &&
                season !== "none" &&
                seasonItem &&
                leaves.map(l => (
                    <FallingImg
                        l={l}
                        seasonItem={seasonItem}
                        scale={scale}
                        key={l.id}
                        fallingImgStyle={fallingImgStyle}
                    />
                ))}
        </>
    );
};
const useStyles = makeStyles<Theme, { screenWidth: number }>({
    festivalImg: ({ screenWidth }) => ({
        position: "absolute",
        width: "128%",
        top: 80 - screenWidth * 0.34,
        left: -(screenWidth * 0.28),
        zIndex: -110,
    }),
});

function FallingImg({
    l,
    seasonItem,
    scale,
    fallingImgStyle,
}: {
    l: Leaf;
    seasonItem: FallingImage;
    scale: number;
    fallingImgStyle?: BaseCSSProperties;
}) {
    const c = useFallingImgStyles({
        scale,
        fallingImgInitialX: l.initialX,
        fallingImgStyle,
    });
    return (
        <img
            key={`falling item ${l.id}`}
            src={appsPublicImg + seasonItem.fileName}
            alt={`${seasonItem.alt} ${l.id}`}
            title={`${seasonItem.alt} ${l.id}`}
            className={spaceBetween("falling", c.fallingImg)}
        />
    );
}
const useFallingImgStyles = makeStyles<
    Theme,
    {
        scale: number;
        fallingImgInitialX: number;
        fallingImgStyle?: BaseCSSProperties;
    }
>({
    fallingImg: ({ scale, fallingImgInitialX, fallingImgStyle }) => ({
        willChange: "animation",
        backfaceVisibility: "hidden",
        maxWidth: 50 * scale,
        maxHeight: 50 * scale,
        position: "fixed",
        top: -1.5 * 90 * scale,
        left: fallingImgInitialX,
        zIndex: -100,
        ...fallingImgStyle,
    }),
});

export async function getFallingImages() {
    const fallingImages = await sFetchJson<FallingImage[]>(
        "api/FallingImage/GetFallingImages"
    );
    return fallingImages;
}

export function getSeasonalAnimationKey(d: Date) {
    const month = d.getMonth() + 1;
    const date = d.getDate();

    if (month >= 9 && month <= 11) {
        //秋
        return "autumn";
    }
    if (month === 12 || month <= 2) {
        if (month === 12 && 12 <= date && date <= 25) {
            return "xmas";
        }
        if (month === 1 && date <= 7) {
            return "kadomatsu";
        }
        //冬
        return "winter";
    }
    if (month >= 3 && month <= 4) {
        //春
        return "spring";
    }
    //夏
    if (month === 7 && date === 7) {
        return "tanabata";
    }
    return "summer";
}
