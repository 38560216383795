import {
    changeAppState,
    getAppState,
} from "../../../../../../../../../common/appState";
import { Item } from "./Item";
import { FriendLimitExpander } from "./ItemClass/FriendLimitExpander";
import { InvitationCard } from "./ItemClass/InvitationCard/InvitationCard";
import { PresentBox } from "./ItemClass/PresentBox";
import { RegisteredEnvelope } from "./ItemClass/RegisteredEnvelope/RegisteredEnvelope";
import { XpBooster } from "./ItemClass/XpBooster/XpBooster";
import { ItemKey, PossessedItem, PossessedItem_ServerSide } from "./types";

export const allItems: Item[] = [
    new PresentBox("xmas_present"),
    new PresentBox("handsel"),
    new PresentBox("small_present_box"),
    new XpBooster("xp_booster"),
    new InvitationCard(),
    new RegisteredEnvelope(),
    new FriendLimitExpander(),
    new PresentBox("present_box"),
    new PresentBox("big_present_box"),
    new PresentBox("daily_present_box"),
    new PresentBox("weekly_present_box"),
    new XpBooster("ultra_booster"),
    new PresentBox("present_box_tower"),
];

export function setItemsFromServerSide(
    itemsFromServer: PossessedItem_ServerSide[],
    keysToKeepOrder?: ItemKey[]
) {
    changeAppState(
        "items",
        convertPossessedItemsForFrontend(itemsFromServer, keysToKeepOrder)
    );
}

function convertPossessedItemsForFrontend(
    itemsFromServer: PossessedItem_ServerSide[],
    keysToKeepOrder: ItemKey[] = []
): PossessedItem[] {
    const keysOnlyFromServer = itemsFromServer
        .filter(
            itemFromServer => !keysToKeepOrder.includes(itemFromServer.itemKey)
        )
        .map(item => item.itemKey);
    const orderedKeys = keysToKeepOrder.concat(keysOnlyFromServer);

    return orderedKeys.reduce<PossessedItem[]>((acc, itemKey) => {
        const itemFromServer = itemsFromServer.find(
            item => item.itemKey === itemKey
        );
        if (!itemFromServer) {
            return acc;
        }

        const itemInstance = allItems.find(it => it.key === itemKey);
        if (!itemInstance) {
            return acc;
        }

        return [
            ...acc,
            {
                item: itemInstance,
                num: itemFromServer.num,
                isNew: itemFromServer.isNew,
                isAdded: itemFromServer.isAdded,
            },
        ];
    }, []);
}

export function decreaseItemCount(usedItemKey: ItemKey, usedItemCount: number) {
    changeAppState(
        "items",
        getAppState()
            .items.map(item =>
                item.item.key === usedItemKey
                    ? { ...item, num: item.num - usedItemCount }
                    : item
            )
            .filter(item => item.num > 0)
    );
}
