import { makeStyles, Theme } from "@material-ui/core/styles";
import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";
import ExclamationIcon from "@material-ui/icons/Error";
import { MouseEventHandler } from "react";
import { spaceBetween } from "../common/util/Array/spaceBetween";

export function AlertIcon({
    size,
    style,
    className,
    onClick,
}: {
    size: number;
    style?: BaseCSSProperties;
    className?: string;
    onClick?: MouseEventHandler<HTMLDivElement>;
}) {
    const c = useAlertIconStyles({ size, style });
    return (
        <div className={spaceBetween(c.container, className)} onClick={onClick}>
            <ExclamationIcon className={c.icon} />
        </div>
    );
}
const useAlertIconStyles = makeStyles<
    Theme,
    { size: number; style?: BaseCSSProperties }
>(theme => ({
    container: ({ size, style }) => ({
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: size,
        height: size,
        borderRadius: "50%",
        ...style,
    }),
    icon: ({ size }) => ({
        color: theme.palette.secondary.main,
        backgroundColor: "white",
        width: (size * 24) / 22,
        height: (size * 24) / 22,
    }),
}));
