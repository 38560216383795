import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import * as React from "react";
import { ReactNode, useEffect, useState } from "react";
import { connect } from "react-redux";
import Container from "reactstrap/lib/Container";
import { compose } from "recompose";
import { AppState } from "../../../common/appState";
import { sleepAsync } from "../../../common/functions";
import * as baseStore from "../../../store/BaseStore";
import { ApplicationState } from "../../../store/configureStore";
import Footer from "./Footer/Footer";
import "./Layout.css";
import NavMenu from "./NavMenu";

interface OuterProps {
    children: React.ReactNode;
}

type InnerProps = OuterProps & baseStore.BaseState;

function Layout({ children, isHeaderShown, isFooterShown }: InnerProps) {
    const [panels, setPanels] = useState<ReactNode[]>([]);

    useEffect(() => {
        (async () => {
            await sleepAsync(500);
            const earlyPanels = await dynamicImportPanelsAndDialogs(true);
            setPanels(earlyPanels);

            await sleepAsync(3500);
            const latePanels = await dynamicImportPanelsAndDialogs(false);
            setPanels([...earlyPanels, ...latePanels]);
        })();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <div>
                {isHeaderShown && <NavMenu />}

                <Container className="contents-container">{children}</Container>

                <Footer isFooterShown={isFooterShown} />

                {panels}
            </div>
        </ThemeProvider>
    );
}

export const panelsToImport: {
    importComponent: () => Promise<{ default: () => JSX.Element }>;
    getOpenStatus: (state: AppState) => boolean | number;
    early: boolean;
}[] = [
    {
        importComponent: () => import("../../shared/Author/Author"),
        getOpenStatus: state => state.authorPanelState.open,
        early: true,
    },
    {
        importComponent: () => import("./Login/Panel"),
        getOpenStatus: state => state.signInPanelState.type !== "close",
        early: true,
    },
    {
        importComponent: () =>
            import("../../shared/User/OtherUserPanel/OtherUserPanel"),
        getOpenStatus: state =>
            state.otherUserPanelsState.filter(panelState => panelState.open)
                .length,
        early: true,
    },
    {
        importComponent: () =>
            import("../../shared/Message/MessagePanel/Panel"),
        getOpenStatus: state => state.messagePanelState !== "close",
        early: true,
    },
    {
        importComponent: () => import("../../shared/Panel/Social/Panel"),
        getOpenStatus: state => state.socialPanelState.open,
        early: true,
    },
    {
        importComponent: () => import("../../shared/Panel/VocabRoulette/Panel"),
        getOpenStatus: state => state.vocabRoulettePanelState.open,
        early: false,
    },
    {
        importComponent: () => import("../../shared/Panel/Shop/Panel"),
        getOpenStatus: state => state.shopPanelState.open,
        early: false,
    },
    {
        importComponent: () => import("../../shared/Payment/Panel"),
        getOpenStatus: state => state.paymentPanelState !== "close",
        early: false,
    },
    {
        importComponent: () => import("../../shared/User/ActiveUsers/Panel"),
        getOpenStatus: state => state.activeUsersPanelState.open,
        early: false,
    },
    {
        importComponent: () => import("../../shared/Panel/ImageDetail/Panel"),
        getOpenStatus: state => state.imageDetailPanelState.open,
        early: false,
    },
    {
        importComponent: () =>
            import(
                "./Login/MyPage/components/OpenableCards/ItemsCard/items/ItemClass/InvitationCard/Panel"
            ),
        getOpenStatus: state => state.invitationCardPanelState.open,
        early: false,
    },
    {
        importComponent: () =>
            import(
                "./Login/MyPage/components/OpenableCards/ItemsCard/items/ItemClass/RegisteredEnvelope/Panel"
            ),
        getOpenStatus: state => state.registeredEnvelopePanelState.open,
        early: false,
    },
];

export const dialogsToImport: {
    importComponent: () => Promise<{ default: () => JSX.Element }>;
    early: boolean;
}[] = [
    {
        importComponent: () =>
            import("../../shared/Dialog/ResultXpDialog/GuestUser"),
        early: false,
    },
    {
        importComponent: () =>
            import("../../shared/Dialog/ResultXpDialog/RegisteredUser"),
        early: false,
    },
    {
        importComponent: () =>
            import("../../shared/Dialog/FriendsLimitDialog/FriendsLimitDialog"),
        early: false,
    },
    {
        importComponent: () =>
            import(
                "../../shared/Dialog/GuestMessageLimitDialog/GuestMessageLimitDialog"
            ),
        early: false,
    },
    {
        importComponent: () =>
            import("../../shared/Dialog/AddCoinsDialog/AddCoinsDialog"),
        early: true,
    },
    {
        importComponent: () =>
            import("../../shared/Dialog/SimpleDialog/SimpleDialog"),
        early: true,
    },
];

async function dynamicImportPanelsAndDialogs(early: boolean) {
    const PanelComponents = [...panelsToImport, ...dialogsToImport]
        .filter(c => c.early === early)
        .map(async (p, i) => {
            const { default: Component } = await p.importComponent();
            return (
                <Component key={`${p.early ? "early" : "late"}-panel${i}`} />
            );
        });
    return await Promise.all(PanelComponents);
}

export const theme = createTheme({
    palette: { primary: { main: "#007BFF" }, secondary: { main: "#dc3545" } },
    typography: {
        button: {
            textTransform: "none",
        },
    },
});

export default compose<InnerProps, OuterProps>(
    connect((state: ApplicationState) => state.base)
)(Layout);
