import { makeStyles, Theme } from "@material-ui/core/styles";
import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { useMemo } from "react";
import { changeAppState, useAppState } from "../../../../../common/appState";
import { spaceBetween } from "../../../../../common/util/Array/spaceBetween";
import { AlertIcon } from "../../../../../icon/AlertIcon";
import { UnreadComment } from "../../../../shared/Panel/Social/Panel/types";

export function SocialIcon({
    containerStyle,
    isOpenHamburger,
    transitionClass,
}: {
    containerStyle?: BaseCSSProperties;
    isOpenHamburger?: boolean;
    transitionClass: "t500ms" | "t1s";
}) {
    const c = useSocialIconStyles({ containerStyle });

    const [unreadComments] = useAppState("unreadComments");
    const shouldShowAlertIcon = useMemo(
        () => checkSocialAlert(unreadComments),
        [unreadComments]
    );

    return (
        <div
            className={spaceBetween(c.container, transitionClass)}
            onClick={() => {
                changeAppState("socialPanelState", { open: true });
            }}
        >
            {shouldShowAlertIcon && !isOpenHamburger && (
                <AlertIcon
                    style={{
                        position: "absolute",
                        top: -5,
                        right: -5,
                        zIndex: 1,
                    }}
                    size={22}
                />
            )}

            <Icon
                isOpenHamburger={isOpenHamburger}
                transitionClass={transitionClass}
            />
        </div>
    );
}
const useSocialIconStyles = makeStyles<
    Theme,
    { containerStyle?: BaseCSSProperties }
>({
    container: ({ containerStyle }) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        ...containerStyle,
    }),
});

function Icon({
    isOpenHamburger,
    transitionClass,
}: {
    isOpenHamburger?: boolean;
    transitionClass: string;
}) {
    const c = useIconStyles();

    return (
        <FavoriteIcon
            className={spaceBetween(
                isOpenHamburger ? c.iconForOpenHamburger : c.icon,
                transitionClass,
                c.iconBase
            )}
        />
    );
}
const useIconStyles = makeStyles({
    iconBase: {
        transform: "scale(.8)",
        color: "white",
        position: "relative",
        top: 2,
    },
    icon: {
        width: 40,
        height: 40,
        cursor: "pointer",
    },
    iconForOpenHamburger: {
        width: 0,
        height: 0,
    },
});

function checkSocialAlert(unreadComments: UnreadComment[]): boolean {
    if (unreadComments.length > 0) {
        return true;
    }
    return false;
}
