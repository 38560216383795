import React from "react";
import { decreaseItemCount, setItemsFromServerSide } from ".";
import {
    changeAppState,
    getAppState,
} from "../../../../../../../../../common/appState";
import { fetchPost } from "../../../../../../../../../common/util/fetch";
import { PrimaryButtonProps } from "../../../../../../../../shared/Button/PrimaryButton";
import { User } from "../../../../../../../../shared/User/types";
import { ItemKey, PossessedItem_ServerSide } from "./types";

export interface SoldItem extends Omit<Item, "price"> {
    price: number;
}

export abstract class Item {
    abstract key: ItemKey;
    abstract name: string;
    abstract price: number | "notSold";
    abstract shouldUseOneByOne: boolean;
    abstract UseButton?: React.FC<PrimaryButtonProps>;
    abstract getExplanation: (user?: User) => string;
    protected abstract afterUsingItem: (
        userId: number,
        usedCount: number
    ) => Promise<void>;
    protected abstract previousCheck: (usedCount: number) => Promise<boolean>;

    shouldWaitFetchResult: boolean = true; // Override if the behavior of the child should be changed

    public use = async (
        originalOrderKeys?: ItemKey[],
        usedItemCount: number = 1
    ) => {
        const { user, items } = getAppState();
        const item = items.find(({ item }) => item.key === this.key);
        if (!user || !item || item.num < usedItemCount) {
            return;
        }

        if (!(await this.previousCheck(usedItemCount))) {
            return;
        }

        if (this.shouldWaitFetchResult) {
            // With await
            await this.fetchServerSideProcess(
                user,
                usedItemCount,
                originalOrderKeys
            );
            return;
        }
        // Without await
        this.fetchServerSideProcess(user, usedItemCount, originalOrderKeys);
        decreaseItemCount(this.key, usedItemCount);
    };

    private fetchServerSideProcess = async (
        user: User,
        usedItemCount: number,
        originalOrderKeys?: ItemKey[]
    ) => {
        const result = await fetchUseItem(user.userId, this.key, usedItemCount);
        if (!result) {
            return;
        }
        await this.afterUsingItem(user.userId, usedItemCount);

        setItemsFromServerSide(result.items, originalOrderKeys);
        changeAppState("user", result.user);
    };
}

type Result = { items: PossessedItem_ServerSide[]; user: User };
async function fetchUseItem(
    userId: number,
    itemKey: string,
    count: number
): Promise<Result | null> {
    const result = await fetchPost<
        {
            userId: number;
            itemKey: string;
            count: number;
        },
        Result
    >("api/Item/UseItem", { userId, itemKey, count });

    if ("error" in result) {
        return null;
    }
    return result;
}
