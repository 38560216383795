import { makeStyles, Theme } from "@material-ui/core/styles";
import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";
import { useEffect, useState } from "react";
import Button from "reactstrap/lib/Button";
import { BLOB_URL, youtubeSubscriptionUrl } from "../../../common/consts";
import { sendClientOpeLog } from "../../../common/functions";
import { fetchPostFormDataBlobResponse } from "../../../common/util/fetch";
import ShurikenProgress from "../Animations/ShurikenProgress";
import { ATargetBlank } from "../Link/ATargetBlank";

export interface StorageVideoProps {
    path: string;
    screenWidth: number;
    pageNameForLog: string;
    style?: BaseCSSProperties;
    buttonLabel?: string;
}

export function StorageVideo(props: StorageVideoProps) {
    return <Video isStorage {...props} />;
}

export interface VideoProps extends StorageVideoProps {
    isStorage?: boolean;
}

export function Video({
    path,
    screenWidth,
    pageNameForLog,
    style,
    buttonLabel,
    isStorage,
}: VideoProps) {
    const isWide = screenWidth > 600;
    const c = useStyles({ isWide, style });

    const [blobUrl, setBlobUrl] = useState("");
    useEffect(() => {
        if (!path) {
            return;
        }
        if (!isStorage && !path.includes(BLOB_URL)) {
            setBlobUrl(path);
            return;
        }

        if (navigator.userAgent.includes("iPhone OS")) {
            // iPhoneではLingual Ninjaから受け取った動画が動かないためストレージから直接取得（iPhoneはもともとSeekもできるのでOK）
            setBlobUrl(isStorage ? `${BLOB_URL}/${path}` : path);
            return;
        }

        // Azure Storageが動画のSeekingに対応していないため、Lingual Ninjaのサーバーを介して動画を取得
        setBlobUrl("");
        const formData = new FormData();
        formData.append("pathName", path.replace(`${BLOB_URL}/`, ""));
        fetchPostFormDataBlobResponse(
            "api/SystemBase/GetStorageFile",
            formData
        ).then(r => {
            if ("error" in r) {
                return;
            }
            setBlobUrl(URL.createObjectURL(r));
        });
        return;
    }, [isStorage, path]);

    return (
        <div className={c.container}>
            <div className={c.innerContainer}>
                <div className={c.videoContainer}>
                    {blobUrl ? (
                        <video src={blobUrl} className={c.video} controls />
                    ) : (
                        <ShurikenProgress className={c.video} size={50} />
                    )}
                </div>
                <ATargetBlank
                    nofollow
                    href={youtubeSubscriptionUrl}
                    onClick={() => {
                        setTimeout(() => {
                            sendClientOpeLog(
                                "click YouTube channel",
                                `from ${pageNameForLog} video bottom`
                            );
                        }, 1000);
                    }}
                >
                    <Button className={c.subscribeButton}>
                        {buttonLabel ||
                            "Click to subscribe to this YouTube channel!"}
                    </Button>
                </ATargetBlank>
            </div>
        </div>
    );
}
const useStyles = makeStyles<
    Theme,
    { isWide: boolean; style?: BaseCSSProperties }
>({
    container: ({ isWide, style }) => ({
        backgroundColor: isWide ? "rgb(231, 233, 231)" : undefined,
        padding: "5px 0",
        border: 0,
        ...style,
    }),
    innerContainer: { maxWidth: 600 },
    videoContainer: {
        position: "relative",
        width: "100%",
        paddingTop: "56.25%",
    },
    video: {
        position: "absolute",
        top: 0,
        right: 0,
        width: "100%",
        height: "100%",
    },
    subscribeButton: {
        marginTop: 5,
        width: "100%",
        backgroundColor: "red",
        color: "white",
        transition: "all 300ms",
        "&:hover": {
            opacity: 0.7,
            backgroundColor: "red",
            color: "white",
            transform: "scale(1.05, 1.05)",
        },
    },
});
