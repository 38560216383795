import Button, { ButtonProps } from "@material-ui/core/Button";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";
import { forwardRef } from "react";
import { spaceBetween } from "../../../common/util/Array/spaceBetween";

export type PrimaryButtonProps = { style?: BaseCSSProperties } & Omit<
    ButtonProps,
    "style"
>;

export const PrimaryButton = forwardRef<HTMLButtonElement, PrimaryButtonProps>(
    ({ style, className, ...rest }, ref) => {
        const c = useStyles({ style });
        return (
            <Button
                variant="contained"
                color="primary"
                {...rest}
                className={spaceBetween(className, c.button)}
                ref={ref}
            />
        );
    }
);
const useStyles = makeStyles<Theme, { style?: BaseCSSProperties }>({
    button: ({ style }) => ({
        fontSize: "medium",
        ...style,
    }),
});
