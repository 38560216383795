const periodReplaceKey = "__p__";
export const invitationHashKey = "#invitation:";

export function encodeEmailForUrl(email: string) {
    return encodeURIComponent(email.replaceAll(".", periodReplaceKey));
}

export function decodeEmailForUrl(encodedEmail: string) {
    return decodeURIComponent(encodedEmail).replaceAll(periodReplaceKey, ".");
}

