import { makeStyles, Theme } from "@material-ui/core/styles";
import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";
import { useEffect, useState } from "react";
import { changeAppState } from "../../../../../../common/appState";
import { fetchGet } from "../../../../../../common/util/fetch";
import { InvalidImage } from "../../../../../../icon/InvalidImage";
import ShurikenProgress from "../../../../Animations/ShurikenProgress";

type MessageType = "MyMessage" | "TheirMessage";

export function ImageMessage({
    myUserId,
    targetUserId,
    messageNo,
    messageType,
    isSending,
    imgStyle,
}: {
    myUserId: number;
    targetUserId: number;
    messageNo: number;
    messageType: MessageType;
    isSending?: boolean;
    imgStyle?: BaseCSSProperties;
}) {
    const [src, setSrc] = useState("");
    useEffect(() => {
        if (isSending) {
            return;
        }
        fetchGet<
            {
                myUserId: number;
                targetUserId: number;
                messageNo: number;
            },
            { imageDataSrc: string }
        >("api/Message/GetImageSrc", {
            myUserId,
            targetUserId,
            messageNo,
        }).then(result => {
            if ("error" in result) {
                return;
            }
            setSrc(result.imageDataSrc);
        });
    }, [isSending]);

    const c = useImageStyles({ messageType, imgStyle });

    if (!src) {
        return <ShurikenProgress size={30} className={c.shurikenContainer} />;
    }
    if (src === "expired" || src === "deleted") {
        return (
            <div className={c.expiredContainer}>
                <InvalidImage className={c.expiredImageIcon} />
                <div>This photo is no longer available.</div>
            </div>
        );
    }
    return (
        <img
            src={src}
            alt="image in message"
            className={c.img}
            onClick={() => {
                changeAppState("imageDetailPanelState", {
                    open: true,
                    src,
                });
            }}
        />
    );
}
const useImageStyles = makeStyles<
    Theme,
    { messageType: MessageType; imgStyle?: BaseCSSProperties }
>(theme => ({
    shurikenContainer: { padding: 20 },
    expiredContainer: ({ messageType }) => ({
        zIndex: 1,
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
        padding: 10,
        color: messageType === "MyMessage" ? "white" : theme.palette.grey[700],
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
    }),
    expiredImageIcon: {
        width: 70,
        height: 70,
        display: "block",
        marginBottom: 5,
    },
    img: ({ imgStyle }) => ({
        width: "100%",
        zIndex: 1,
        borderRadius: 8,
        cursor: "pointer",
        ...imgStyle,
    }),
}));
