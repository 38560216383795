import { useEffect, useState } from "react";

export function useOpenAnimationFinished(
    isCompletelyOpened: boolean,
    transitionMilliseconds: number
) {
    const [isOpenAnimationFinished, setOpenAnimationFinished] = useState(false);
    useEffect(() => {
        if (isCompletelyOpened) {
            const timeoutId = setTimeout(() => {
                setOpenAnimationFinished(true);
            }, transitionMilliseconds);
            return () => {
                clearTimeout(timeoutId);
            };
        }
        setOpenAnimationFinished(false);
    }, [isCompletelyOpened, transitionMilliseconds]);

    return { isOpenAnimationFinished };
}
