import { Theme, makeStyles } from "@material-ui/core/styles";
import { SyntheticEvent } from "react";
import { changeAppState } from "../../../../common/appState";
import { spaceBetween } from "../../../../common/util/Array/spaceBetween";
import ShurikenProgress from "../../Animations/ShurikenProgress";
import { YouTubeVideo } from "../../YouTubeVideo";

const multipleKey = "multiple::"; // Corresponding value is in: Z-Apps\Models\Post\PostService.cs
export const shurikenProgressKey = "ShurikenProgress";

export const SocialPostImageRender = ({
    src,
    alt,
}: {
    src?: string;
    alt?: string;
}) => {
    if (!src || !alt) {
        return null;
    }
    if (src === shurikenProgressKey) {
        return <ShurikenProgress size={30} style={{ margin: "15px 0" }} />;
    }
    if (src.startsWith("youtube")) {
        return (
            <YouTubeVideo
                screenWidth={500}
                pageNameForLog={"markDown embedded in Social Post"}
                videoId={alt}
                buttonLabel={
                    src.includes("-")
                        ? src.split("-")[1].split("_").join(" ")
                        : ""
                }
            />
        );
    }
    if (src.startsWith(multipleKey)) {
        const srcArray: string[] = JSON.parse(src.replace(multipleKey, ""));
        if (srcArray.length === 1) {
            return <SocialPostSingleImageRender src={srcArray[0]} alt={alt} />;
        }
        return <SocialPostMultipleImagesRender srcArray={srcArray} alt={alt} />;
    }
    return <SocialPostSingleImageRender src={src} alt={alt} />;
};

const SocialPostSingleImageRender = ({
    src,
    alt,
}: {
    src: string;
    alt: string;
}) => {
    const c = useSocialPostSingleImageStyle();
    return (
        <div className={c.container}>
            <img
                src={src}
                alt={alt}
                className={spaceBetween(`social-post-image-${alt}`, c.img)}
                onClick={getOnClickSocialImage(src)}
            />
        </div>
    );
};
const useSocialPostSingleImageStyle = makeStyles({
    container: {
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0px auto 20px",
        position: "relative",
        top: 10,
        width: "100%",
    },
    img: {
        objectFit: "contain",
        borderRadius: 7,
        maxWidth: "calc(100% - 10px)",
        maxHeight: 450,
        cursor: "pointer",
    },
});

const SocialPostMultipleImagesRender = ({
    srcArray,
    alt,
}: {
    srcArray: string[];
    alt: string;
}) => {
    const srcLength = srcArray.length;
    const c = useMultipleImagesStyles({ isLengthTwo: srcLength === 2 });

    const arrayToShow = srcLength % 2 ? srcArray : ["", ...srcArray];
    const [firstImageSrc, ...restSrc] = arrayToShow;

    return (
        <div className={c.container}>
            {firstImageSrc && (
                <img
                    src={firstImageSrc}
                    alt={alt}
                    className={spaceBetween(
                        `social-post-image-${alt}`,
                        c.firstImage
                    )}
                    onClick={getOnClickSocialImage(firstImageSrc)}
                />
            )}
            {restSrc.map(src => (
                <img
                    key={src}
                    src={src}
                    alt={alt}
                    className={spaceBetween(
                        `social-post-image-${alt}`,
                        c.image
                    )}
                    onClick={getOnClickSocialImage(src)}
                />
            ))}
        </div>
    );
};

const multipleImagesGap = 2;
const useMultipleImagesStyles = makeStyles<Theme, { isLengthTwo: boolean }>({
    container: {
        display: "inline-flex",
        gap: multipleImagesGap,
        margin: "0px auto 20px",
        width: "calc(100% - 10px)",
        position: "relative",
        top: 10,
        borderRadius: 7,
        overflow: "hidden",
        flexWrap: "wrap",
    },
    firstImage: {
        objectFit: "cover",
        objectPosition: "50% 50%",
        cursor: "pointer",
        width: "100%",
        height: 150,
    },
    image: ({ isLengthTwo }) => ({
        objectFit: "cover",
        objectPosition: "50% 50%",
        cursor: "pointer",
        width: `calc(50% - ${multipleImagesGap / 2}px)`,
        height: isLengthTwo ? 130 : 100,
    }),
});

const getOnClickSocialImage = (src: string) => (ev: SyntheticEvent) => {
    ev.stopPropagation();
    changeAppState("imageDetailPanelState", {
        open: true,
        src,
    });
};
