import { makeStyles, Theme } from "@material-ui/core/styles";
import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";
import * as React from "react";
import { spaceBetween } from "../../../common/util/Array/spaceBetween";
import "./style.css";

interface Props {
    style?: BaseCSSProperties;
    children: React.ReactNode;
    hoverScale?: boolean;
}
export const ScrollBox = ({ children, style, hoverScale }: Props) => {
    const c = useStyles({ style });
    return (
        <div
            className={spaceBetween(
                "style-scroll",
                hoverScale && "hoverScale05",
                c.container
            )}
        >
            {children}
        </div>
    );
};
const useStyles = makeStyles<Theme, { style?: BaseCSSProperties }>({
    container: ({ style }) => ({ ...style }),
});
