import { KanaStatus, KanaType } from "./types";

export function getKanaPercentage(kanaType: KanaType) {
    const json = localStorage.getItem(`KanaQuizStatus-${kanaType}`);
    const kanaStatus: KanaStatus = json ? JSON.parse(json) : {};

    return getKanaPercentageFromStatus(kanaType, kanaStatus);
}

export function getKanaPercentageFromStatus(
    kanaType: KanaType,
    kanaStatus: KanaStatus
) {
    const allKanaNumber = Object.keys(
        kanaType === "Hiragana" ? hiraganaList : katakanaList
    ).length;

    const rememberedCharactersNumber = Object.values(kanaStatus).filter(
        k => k
    ).length;

    return Math.floor((100 * rememberedCharactersNumber) / allKanaNumber);
}

export const hiraganaList = {
    a: "あ",
    i: "い",
    u: "う",
    e: "え",
    o: "お",
    ka: "か",
    ki: "き",
    ku: "く",
    ke: "け",
    ko: "こ",
    sa: "さ",
    shi: "し",
    su: "す",
    se: "せ",
    so: "そ",
    ta: "た",
    chi: "ち",
    tsu: "つ",
    te: "て",
    to: "と",
    na: "な",
    ni: "に",
    nu: "ぬ",
    ne: "ね",
    no: "の",
    ha: "は",
    hi: "ひ",
    fu: "ふ",
    he: "へ",
    ho: "ほ",
    ma: "ま",
    mi: "み",
    mu: "む",
    me: "め",
    mo: "も",
    ya: "や",
    yu: "ゆ",
    yo: "よ",
    ra: "ら",
    ri: "り",
    ru: "る",
    re: "れ",
    ro: "ろ",
    wa: "わ",
    _wo: "を",
    n: "ん",
    ga: "が",
    gi: "ぎ",
    gu: "ぐ",
    ge: "げ",
    go: "ご",
    za: "ざ",
    ji: "じ",
    zu: "ず",
    ze: "ぜ",
    zo: "ぞ",
    da: "だ",
    _ji: "ぢ",
    _zu: "づ",
    de: "で",
    do: "ど",
    ba: "ば",
    bi: "び",
    bu: "ぶ",
    be: "べ",
    bo: "ぼ",
    pa: "ぱ",
    pi: "ぴ",
    pu: "ぷ",
    pe: "ぺ",
    po: "ぽ",
    kya: "きゃ",
    kyu: "きゅ",
    kyo: "きょ",
    sha: "しゃ",
    shu: "しゅ",
    sho: "しょ",
    cha: "ちゃ",
    chu: "ちゅ",
    cho: "ちょ",
    nya: "にゃ",
    nyu: "にゅ",
    nyo: "にょ",
    hya: "ひゃ",
    hyu: "ひゅ",
    hyo: "ひょ",
    mya: "みゃ",
    myu: "みゅ",
    myo: "みょ",
    rya: "りゃ",
    ryu: "りゅ",
    ryo: "りょ",
    gya: "ぎゃ",
    gyu: "ぎゅ",
    gyo: "ぎょ",
    ja: "じゃ",
    ju: "じゅ",
    jo: "じょ",
    bya: "びゃ",
    byu: "びゅ",
    byo: "びょ",
    pya: "ぴゃ",
    pyu: "ぴゅ",
    pyo: "ぴょ",
} as const;

export const katakanaList = {
    a: "ア",
    i: "イ",
    u: "ウ",
    e: "エ",
    o: "オ",
    ka: "カ",
    ki: "キ",
    ku: "ク",
    ke: "ケ",
    ko: "コ",
    sa: "サ",
    shi: "シ",
    su: "ス",
    se: "セ",
    so: "ソ",
    ta: "タ",
    chi: "チ",
    tsu: "ツ",
    te: "テ",
    to: "ト",
    na: "ナ",
    ni: "ニ",
    nu: "ヌ",
    ne: "ネ",
    no: "ノ",
    ha: "ハ",
    hi: "ヒ",
    fu: "フ",
    he: "ヘ",
    ho: "ホ",
    ma: "マ",
    mi: "ミ",
    mu: "ム",
    me: "メ",
    mo: "モ",
    ya: "ヤ",
    yu: "ユ",
    yo: "ヨ",
    ra: "ラ",
    ri: "リ",
    ru: "ル",
    re: "レ",
    ro: "ロ",
    wa: "ワ",
    _wo: "ヲ",
    n: "ン",
    ga: "ガ",
    gi: "ギ",
    gu: "グ",
    ge: "ゲ",
    go: "ゴ",
    za: "ザ",
    ji: "ジ",
    zu: "ズ",
    ze: "ゼ",
    zo: "ゾ",
    da: "ダ",
    _ji: "ヂ",
    _zu: "ヅ",
    de: "デ",
    do: "ド",
    ba: "バ",
    bi: "ビ",
    bu: "ブ",
    be: "ベ",
    bo: "ボ",
    pa: "パ",
    pi: "ピ",
    pu: "プ",
    pe: "ペ",
    po: "ポ",
    kya: "キャ",
    kyu: "キュ",
    kyo: "キョ",
    sha: "シャ",
    shu: "シュ",
    sho: "ショ",
    cha: "チャ",
    chu: "チュ",
    cho: "チョ",
    nya: "ニャ",
    nyu: "ニュ",
    nyo: "ニョ",
    hya: "ヒャ",
    hyu: "ヒュ",
    hyo: "ヒョ",
    mya: "ミャ",
    myu: "ミュ",
    myo: "ミョ",
    rya: "リャ",
    ryu: "リュ",
    ryo: "リョ",
    gya: "ギャ",
    gyu: "ギュ",
    gyo: "ギョ",
    ja: "ジャ",
    ju: "ジュ",
    jo: "ジョ",
    bya: "ビャ",
    byu: "ビュ",
    byo: "ビョ",
    pya: "ピャ",
    pyu: "ピュ",
    pyo: "ピョ",
} as const;
