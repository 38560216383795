import { forwardRef, useEffect } from "react";
import {
    changeAppState,
    getAppState,
    useAppState,
} from "../../../../../../../../../../../common/appState";
import { sleepAsync } from "../../../../../../../../../../../common/functions";
import { capitalizeInitialChar } from "../../../../../../../../../../../common/util/String/capitalizeInitialChar";
import { fetchPost } from "../../../../../../../../../../../common/util/fetch";
import {
    PrimaryButton,
    PrimaryButtonProps,
} from "../../../../../../../../../../shared/Button/PrimaryButton";
import { Item } from "../../Item";
import { XpBoosterKey, XpBoosterState_ServerSide } from "./types";

export class XpBooster extends Item {
    public readonly name: string;
    public readonly price: number;
    public readonly boostRate: number;
    public readonly boostMessage: string;
    public readonly shouldUseOneByOne = true;

    constructor(public key: XpBoosterKey) {
        super();
        this.name = key
            .split("_")
            .map(k => capitalizeInitialChar(k))
            .join(" ");

        switch (this.key) {
            case "xp_booster": {
                this.price = 100;
                this.boostRate = 2;
                this.boostMessage = "Double XP!";
                break;
            }
            case "ultra_booster": {
                this.price = 30000;
                this.boostRate = 20;
                this.boostMessage = `${this.boostRate}× more XP!`;
                break;
            }
            default: {
                const _exhaustiveCheck: never = this.key;
                this.price = 100;
                this.boostRate = 2;
                this.boostMessage = "Double XP!";
            }
        }
    }

    getExplanation = () => {
        switch (this.key) {
            case "xp_booster": {
                return "You will get double XP for 1 hour!";
            }
            case "ultra_booster": {
                return `You will get ${this.boostRate} times more XP for 1 hour!`;
            }
            default: {
                const _exhaustiveCheck: never = this.key;
                return "";
            }
        }
    };

    UseButton = forwardRef<HTMLButtonElement, PrimaryButtonProps>(
        ({ disabled, ...rest }, ref) => {
            const [xpBoostRemainingSeconds] = useAppState(
                "xpBoostRemainingSeconds"
            );
            const [lastUsedXpBooster] = useAppState("lastUsedXpBooster");
            const isDifferentBooster =
                xpBoostRemainingSeconds > 0 && lastUsedXpBooster !== this.key;
            return (
                <PrimaryButton
                    {...rest}
                    disabled={disabled || isDifferentBooster}
                    ref={ref}
                />
            );
        }
    );

    protected previousCheck = async () => {
        const { xpBoostRemainingSeconds, lastUsedXpBooster } = getAppState();
        if (xpBoostRemainingSeconds > 0 && lastUsedXpBooster !== this.key) {
            // Normally it's impossible to achieve here
            alert(
                "You already used a different type of XP booster! Please wait until it finishes!"
            );
            return false;
        }
        return true;
    };

    protected afterUsingItem = async (userId: number) => {
        await fetchRemainingBoostingTime(userId);

        changeAppState("signInPanelState", {
            type: "myPageTop",
        });
        await sleepAsync(200);
        changeAppState("signInPanelState", {
            type: "myPageTop",
            initialView: "MyPageLevelAndXp",
        });
    };
}

async function fetchRemainingBoostingTime(userId: number): Promise<void> {
    const result = await fetchPost<
        number,
        { xpBoosterState: XpBoosterState_ServerSide }
    >("api/XpBooster/GetCurrentBoosterState", userId);

    if ("error" in result) {
        return;
    }

    changeAppState("lastUsedXpBooster", result.xpBoosterState.type);
    changeAppState(
        "xpBoostRemainingSeconds",
        result.xpBoosterState.remainingSec
    );
}

export function useXpBoostTimer() {
    const [xpBoostRemainingSeconds] = useAppState("xpBoostRemainingSeconds");
    useEffect(() => {
        if (xpBoostRemainingSeconds <= 0) {
            return;
        }

        const intervalId = setInterval(() => {
            const remainingSec = getAppState().xpBoostRemainingSeconds;
            if (remainingSec > 0) {
                changeAppState("xpBoostRemainingSeconds", remainingSec - 1);
            }
        }, 1000);
        return () => {
            clearTimeout(intervalId);
        };
    }, [xpBoostRemainingSeconds > 0]);
}
