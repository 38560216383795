import { AppToMount } from "../..";
import {
    Z_APPS_COPY_HOST,
    Z_APPS_HOST,
    Z_APPS_ORIGIN_HOST,
} from "../../common/consts";

export const zApps: AppToMount = {
    key: "zApps",
    hostname: Z_APPS_HOST,
    getApp: async () => {
        const module = await import("./App");
        return module.App;
    },
};

export const zAppsSource: AppToMount = {
    key: "origin",
    hostname: Z_APPS_ORIGIN_HOST,
    getApp: async () => {
        const module = await import("./App");
        return module.App;
    },
};

export const zAppsCopy: AppToMount = {
    key: "copy",
    hostname: Z_APPS_COPY_HOST,
    getApp: async () => {
        const module = await import("./App");
        return module.App;
    },
};
