import Button, { ButtonProps } from "@material-ui/core/Button";
import { darken, makeStyles, Theme } from "@material-ui/core/styles";
import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";
import { forwardRef } from "react";
import { spaceBetween } from "../../../common/util/Array/spaceBetween";

type Props = { style?: BaseCSSProperties } & Omit<ButtonProps, "style">;

export const SuccessButton = forwardRef<HTMLButtonElement, Props>(
    ({ style, className, ...rest }, ref) => {
        const c = useStyles({ style });
        return (
            <Button
                variant="contained"
                {...rest}
                className={spaceBetween(className, c.button)}
                ref={ref}
            />
        );
    }
);
const useStyles = makeStyles<Theme, { style?: BaseCSSProperties }>(
    ({ palette }) => ({
        button: ({ style }) => ({
            backgroundColor: darken(palette.success.main, 0.05),
            "&:hover": {
                backgroundColor: palette.success.dark,
            },
            color: "white",
            fontSize: "medium",
            ...style,
        }),
    })
);
