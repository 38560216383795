import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import StorefrontIcon from "@material-ui/icons/Storefront";
import { changeAppState } from "../../../../common/appState";
import { SuccessButton } from "../../../shared/Button/SuccessButton";

export function ItemShopButton() {
    const c = useCoinStyles();

    return (
        <Card
            className={c.card}
            onClick={() => {
                changeAppState("shopPanelState", { open: true });
            }}
        >
            <SuccessButton className={c.button} startIcon={<StorefrontIcon />}>
                Shop
            </SuccessButton>
        </Card>
    );
}
const useCoinStyles = makeStyles({
    card: {
        position: "absolute",
        top: -35,
        height: 35,
        right: 5,
        padding: 2,
    },
    button: {
        maxHeight: 30,
        minHeight: 30,
        fontSize: "15px !important",
        lineHeight: 1,
    },
});
