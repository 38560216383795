import { makeStyles, Theme } from "@material-ui/core/styles";
import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";
import { ReactNode } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { spaceBetween } from "../../../common/util/Array/spaceBetween";
import { ATargetBlankWithoutYouTube } from "../Link/ATargetBlank";
import { CodeRender } from "./CodeRender";
import { HeadingRenderer } from "./HeadingRenderer";
import { ImageRender } from "./ImageRender";
import { SocialPostImageRender } from "./ImageRender/SocialPostImage";
import "./index.css";
import { InlineCodeRender } from "./InlineCodeRender";
import { LinkRender, LinkWithoutShadowRender } from "./LinkBlockRender";
import { TableCellRender } from "./Table/TableCellRender";
import { TableRender } from "./Table/TableRender";

interface MarkdownProps {
    source: string;
    style?: BaseCSSProperties;
    section?: boolean;
    noLinkShadow?: boolean;
    noParagraphMarginBottom?: boolean;
    isForEdit?: boolean;
    singleBreakLine?: boolean;
    isSocialPost?: boolean;
    isFontSmaller?: boolean;
}
export function Markdown({ singleBreakLine, ...rest }: MarkdownProps) {
    if (singleBreakLine) {
        return <SingleBreakLineMarkdown {...rest} />;
    }

    const {
        source,
        style,
        section,
        noLinkShadow,
        isForEdit,
        noParagraphMarginBottom,
        isSocialPost,
        isFontSmaller,
    } = rest;

    const markdown = (
        <ReactMarkdown
            renderers={{
                link: isForEdit
                    ? ATargetBlankWithoutYouTube
                    : noLinkShadow
                    ? LinkWithoutShadowRender
                    : LinkRender,
                heading: HeadingRenderer,
                image: isSocialPost ? SocialPostImageRender : ImageRender,
                code: CodeRender,
                inlineCode: InlineCodeRender,
                paragraph: noParagraphMarginBottom
                    ? ParagraphWithoutMarginBottomRender
                    : ParagraphRender,
                table: TableRender,
                tableCell: TableCellRender,
            }}
            plugins={[gfm]}
        >
            {source}
        </ReactMarkdown>
    );

    const c = useMarkDownStyles({ style });

    return section ? (
        <section
            className={spaceBetween(
                "markdownArea",
                c.markdownArea,
                isFontSmaller && "exclude"
            )}
        >
            {markdown}
        </section>
    ) : (
        <div
            className={spaceBetween(
                "markdownArea",
                c.markdownArea,
                isFontSmaller && "exclude"
            )}
        >
            {markdown}
        </div>
    );
}
const useMarkDownStyles = makeStyles<Theme, { style?: BaseCSSProperties }>({
    markdownArea: ({ style }) => ({ wordBreak: "break-word", ...style }),
});

function ParagraphRender({ children }: { children: ReactNode }) {
    return (
        <span
            style={{
                display: "block",
                marginBottom: 15,
            }}
        >
            {children}
        </span>
    );
}

function ParagraphWithoutMarginBottomRender({
    children,
}: {
    children: ReactNode;
}) {
    return (
        <span
            style={{
                display: "block",
            }}
        >
            {children}
        </span>
    );
}

export function SingleBreakLineMarkdown({
    section,
    source,
    ...rest
}: Omit<MarkdownProps, "singleBreakLine">) {
    const brokenLinesText = source
        .split(/\n+/g)
        .map((t, i) =>
            t ? <Markdown key={i} source={t} {...rest} /> : <br key={i} />
        );
    if (section) {
        return <section>{brokenLinesText}</section>;
    }
    return <>{brokenLinesText}</>;
}
